import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

import type { Module } from 'vuex';
import type { RootState } from '@/store/types';
import type { UserState } from './types';

export const state: UserState = {
  isLoggedIn: false,
  isLoggingIn: false,
  users: [],
  msalAuth: null,
  crudState: null,
  usersHasLoaded: false,
  myUser: null,
  userSettings: null,
};

const namespaced = true;

export const userState: Module<UserState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};


