import type { UserRoleEnum } from '@/shared/enum/user-roles.enum';
import type _Vue from 'vue';

function hasAccess(requiredRole: UserRoleEnum, roles: UserRoleEnum[]): boolean {
  if (!roles) return false; // if no roles are provided, assume no access
  if (!requiredRole) return true; // if no required role is provided, assume access
  return roles.includes(requiredRole);
}

export function accessPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$hasAccess = hasAccess;
}