import { HostService } from '@/services/host.service';
import { AlertType } from '@/shared/interface/alert.interface';

import { AlertActions } from '../alerts/actions';
import { GroupActions } from '../group/actions';

import type { IHost } from '@/shared/interface/host.interface';
import type { HostModel } from '@/shared/models/host.model';
import type { ActionTree } from 'vuex';
import type { RootState } from '../types';
import type { HostState } from './types';
export enum HostActions {
  updateHost = 'hosts/updateHost',
  updateHosts = 'hosts/updateHosts',
  addHost = 'hosts/addHost',
  deleteHost = 'hosts/deleteHost',
  clearHostCrudState = 'hosts/clearHostCrudState',
  fetchHosts = 'hosts/ensureLoadedHosts'
}

export const actions: ActionTree<HostState, RootState> = {
  async ensureLoadedHosts({ rootState, dispatch, commit }): Promise<void> {
    const state: any = rootState;
    const impersonationId = state.hosts.impersonationId ?? undefined;
    try {
      const hosts: IHost[] = await new HostService().getAllHosts(impersonationId);
      commit("setHosts", hosts);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true });
      commit("setHosts", []);
    }
    commit('hasLoadedHosts', true);
  },

  async deleteHost({ dispatch, commit }, host: HostModel): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null }); try {
      await new HostService().deleteHost(host.id);
      commit('setCrudState', { loading: false, message: "", success: true });
      commit('removeHostFromState', host.id);
      dispatch(AlertActions.QueueTost, { text: "HOST.CRUD.DELETE_SUCCESS", type: AlertType.Success }, { root: true })
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    }
  },

  async addHost({ dispatch, commit }, host: HostModel): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    try {
      const newHost = await new HostService().addHost(host);
      commit('setCrudState', { loading: false, message: '', success: true });
      commit('addHostToState', newHost);
      dispatch(AlertActions.QueueTost, { text: "HOST.CRUD.CREATE_SUCCESS", type: AlertType.Success }, { root: true })
      dispatch(GroupActions.getGroups, {}, { root: true });
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    }
  },

  async updateHost({ dispatch, commit }, host: HostModel): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    try {
      const updatedHost = await new HostService().updateHost(host);
      commit('updateHostsInState', [updatedHost]);
      commit('setCrudState', { loading: false, message: '', success: true });
      dispatch(AlertActions.QueueTost, { text: "HOST.CRUD.UPDATE_SUCCESS", type: AlertType.Success }, { root: true })
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    }
  },

  async updateHosts({ dispatch, commit }, hosts: HostModel[]): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    try {
      const service = new HostService();
      hosts.map((h: HostModel) => {
        return service.updateHost(h);
      });
      const updatedHosts = await Promise.all(hosts);
      commit('updateHostsInState', updatedHosts);
      commit('setCrudState', { loading: false, message: '', success: true });
      dispatch(AlertActions.QueueTost, { text: "HOST.CRUD.UPDATE_SUCCESS", type: AlertType.Success }, { root: true })
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    }
  },
  clearHostCrudState({ commit }): void {
    commit('clearCrudState');
  }
}