import type { Module } from 'vuex';
import type { RootState } from '../types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import type { AlertsState } from './types';

export const state: AlertsState = {
  alerts: [],
  toasts: []
};

const namespaced = true;

export const alertsState: Module<AlertsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
