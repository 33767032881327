var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.label)?_c('b-form-datepicker',{class:[_vm.getWidth],attrs:{"options":_vm.options,"label":_vm.label,"disabled":_vm.disabled,"id":_vm.id,"boundary":_vm.mode === 'table' ? 'viewport' : '',"label-no-date-selected":'',"date-format-options":{
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}):_c('b-form-group',{attrs:{"label":_vm.label}},[_c('b-form-datepicker',{class:[_vm.getWidth],attrs:{"options":_vm.options,"label":_vm.label,"disabled":_vm.disabled,"id":_vm.id,"date-format-options":{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }