import http from '@/axios-config';
import { ErrorHandler } from '@/shared/errors/error-handler';
import { ErrorMap } from '@/shared/errors/error-map.model';

import { userApiVersion } from './api.version';

import type { IUserSettings } from '@/shared/interface/user-settings.interface';
import type { UserRow } from '@/shared/interface/user-row.interface';
export class UserService extends ErrorHandler {
  public async deleteUser(requestBody: UserRow): Promise<string | null> {
    try {
      await http.delete(`${userApiVersion}/User/DeleteUser/${requestBody.id}`, {
        headers: {
          'Content-type': 'application/json',
        }
      });
      return requestBody.id ?? null;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "USER.CRUD.MISSING_RIGHTS_DELETE",
            statusCode: 403,
          },
          {
            errorMessage: "USER.CRUD.NOT_FOUND",
            statusCode: 404,
          }
        ]));
      return null;
    }
  }

  public async addUser(requestBody: UserRow): Promise<UserRow | null> {
    try {
      const { data } = await http.post(`${userApiVersion}/User/AddUser`, requestBody);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "USER.CRUD.MISSING_RIGHTS_CREATE",
            statusCode: 403,
          },
        ]));
      return null;
    }
  }

  public async updateUser(requestBody: UserRow): Promise<UserRow | null> {
    try {
      const { data } = await http.put(`${userApiVersion}/User/UpdateUser`, requestBody);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "USER.CRUD.NOT_FOUND",
            statusCode: 404,
          },
          {
            errorMessage: "USER.CRUD.MISSING_RIGHTS_UPDATE",
            statusCode: 403,
          }
        ]));
      return null;
    }
  }

  public async getAllUsers(): Promise<UserRow[]> {
    try {
      const { data } = await http.get(`${userApiVersion}/User/GetAllUsers`);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "USER.CRUD.MISSING_RIGHTS_READ",
            statusCode: 403,
          },
        ]));
      return [];
    }
  }

  public async getUser(id: string): Promise<UserRow | null> {
    try {
      const { data } = await http.get(`${userApiVersion}/User/GetUser/${id}`);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "USER.CRUD.NOT_FOUND",
            statusCode: 404,
          },
          {
            errorMessage: "USER.CRUD.MISSING_RIGHTS_READ",
            statusCode: 403,
          }
        ]));
      return null;
    }
  }

  public async updateSingleUser(user: UserRow): Promise<void> {
    try {
      return http.put('/User/UpdateUser', user)
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "USER.CRUD.NOT_FOUND",
            statusCode: 404,
          },
          {
            errorMessage: "USER.CRUD.MISSING_RIGHTS_UPDATE",
            statusCode: 403,
          }
        ]));
    }
  }

  public async getMyUser(): Promise<UserRow | null> {
    try {
      const { data } = await http.get(`${userApiVersion}/User/GetMyUser`);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "USER.CRUD.NOT_FOUND",
            statusCode: 404,
          },
          {
            errorMessage: "USER.CRUD.MISSING_RIGHTS_READ",
            statusCode: 403,
          }
        ]));
      return null;
    }
  }

  public loadSettingsFromStorage(): IUserSettings {
    return JSON.parse(localStorage.getItem('settings'));
  }

  public saveSettingsToStorage(settings: IUserSettings): void {
    if (settings) {
      localStorage.setItem('settings', JSON.stringify(settings));
    } else {
      localStorage.removeItem('settings');
    }
  }
}