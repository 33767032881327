import i18n from '@/i18n';
import { ProjectStatusEnum } from '@/shared/enum/project-status.enum';

import type { Status } from '@/shared/interface/project-status.interface';
import type { GetterTree } from 'vuex';
import type { RootState } from '../types';
import type { ProjectState } from './types';

export enum ProjectGetters {
  projects = "projects/getProjects",
  hasLoadedProjects = "projects/hasLoadedProjects",
  isSavingProject = "projects/isSavingProject",
  crudState = "projects/getProjectCrudState",
  projectStatusList = "projects/getProjectStatusList",
}

export const getters: GetterTree<ProjectState, RootState> = {
  getProjects(state) {
    return state.projects;
  },
  hasLoadedProjects(state) {
    return state.projectsHasLoaded;
  },
  isSavingProject(state) {
    return state.crudState?.loading ?? false;
  },
  getProjectCrudState(state) {
    return state.crudState;
  },
  getProjectStatusList(): Status[] {
    return [
      { value: ProjectStatusEnum.Idea, text: i18n.t('PROJECT_STATUS.Idea').toString() },
      { value: ProjectStatusEnum.Identified, text: i18n.t('PROJECT_STATUS.Identified').toString() },
      { value: ProjectStatusEnum.Active, text: i18n.t('PROJECT_STATUS.Active').toString() },
    ]
  }
}