import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { IProject } from '@/shared/interface/project.interface';
import { Project } from '@/shared/models/project.model';
import type { MutationTree } from 'vuex';
import type { ProjectState } from './types';

export const mutations: MutationTree<ProjectState> = {
  setProjects(state, projects: IProject[]) {
    state.projects = projects?.map((project) => new Project(project));
  },
  hasLoadedProjects(state, payload: boolean) {
    state.projectsHasLoaded = payload
  },
  updateProjectsInStore(state, updatedProjects: IProject[]) {
    const newState = state.projects.map((project) => {
      const updatedProject = updatedProjects.find((updatedProject) => updatedProject.id === project.id);
      return updatedProject ? new Project(updatedProject) : project;
    });
    state.projects = [...newState]
  },
  removeProjectFromStore(state, projectId: string) {
    state.projects = state.projects.filter((project) => project.id !== projectId);
  },
  addProjectToStore(state, project: IProject) {
    state.projects = [...state.projects ?? [], new Project(project)];
  },
  setCrudState(state, crud: ICrudState) {
    state.crudState = crud;
  },
  clearCrudState(state, payload?: number) {
    if (payload && payload > 0) {
      setTimeout(() => {
        state.crudState = null;
      }, payload);
    } else {
      state.crudState = null;
    }
  }
}