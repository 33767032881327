import i18n from '@/i18n';
import { Unit } from '@/shared/enum/unit.enum';

import type { ResourceType } from '@/shared/models/resource-type.model';

const valueOrDefault = (value: any): any => {
  return (value || value === 0) && value !== '' ? value : "-";
}

const formatNumber = (num: number, notation: 'compact' | 'standard' = 'standard', locale = "en-US"): string => {
  if (+num || num == 0) {
    return Intl.NumberFormat(locale, {
      notation: notation,
      currency: "eur",
      compactDisplay: "short",
      minimumIntegerDigits: 1,
      maximumFractionDigits: num >= 10 ? 0 : 1,
    }).format(num).replace('K', 'k');
  } else {
    return null;
  }
}

const formatPercentage = (num: number, maximumFractionDigits = 0, locale = "en-US"): string => {
  if (+num || num == 0) {
    return Intl.NumberFormat(locale, {
      maximumFractionDigits: maximumFractionDigits,
      notation: "compact",
      compactDisplay: "short",
      minimumSignificantDigits: 1,
      maximumSignificantDigits: 1
    }).format(num) + '%';
  } else {
    return '-';
  }
}

const splitStringIntoValueAndUnit = (valueAndUnit: string): { value: string, unit: string } => {
  const indexOfFirstLetter = valueAndUnit?.search(/[A-Za-z]/);
  const value = valueAndUnit?.substring(0, indexOfFirstLetter);
  const unit = valueAndUnit?.substring(indexOfFirstLetter);
  if (indexOfFirstLetter === -1) {
    return { value: unit, unit: '' }; // reverse order due to the way the string is split
  } else {
    return { value: value, unit: unit };
  }
}

const formatRating = (rating: number): string => {
  return formatNumber(+rating?.toFixed(1), "compact")
}

const formatResourceAndUnit = (resourceType: ResourceType, notation: 'compact' | 'standard' = 'standard'): number | string => {
  const formattedNumber = formatNumber(resourceType?.value, notation);
  if (formattedNumber) {
    if (resourceType.unitInText) {
      return (formattedNumber + " " + resourceType.unitInText);
    }
    else if (resourceType.unit !== Unit.None) {
      return ((formattedNumber + " " + i18n.t("UNIT." + resourceType?.unit).toString())
      );
    }
  }
  return '-';
}

const removeWhiteSpace = (str: string): string => {
  return str?.replace(/\s/g, '') ?? '';
}

const formatDate = (date: string): string =>
  date ? (new Date(date)).toISOString().split('T')[0] : "-";

const formatCurrency = (value: string | number, prefix?: string, suffix?: string): string => {
  prefix = prefix ?? '';
  suffix = suffix ?? '';
  return value ? `${value} ${prefix}${suffix}` : "-"
};

export {
  valueOrDefault,
  formatNumber,
  formatResourceAndUnit,
  splitStringIntoValueAndUnit,
  formatPercentage,
  formatDate,
  formatRating,
  formatCurrency,
  removeWhiteSpace
}