import * as msal from '@azure/msal-browser';

const loginRequest = {
  scopes: ["openid", "profile", "User.Read"]
};

const silentRequest = {
  scopes: ["openid", "profile"],
  loginHint: 'preferred_username'
};

const getMsalConfig = (): msal.Configuration => {
  return {
    auth: {
      clientId: window.VUE_APP_CLIENT_ID,
      authority: window.VUE_APP_AUTHORITY,
      redirectUri: window.VUE_APP_REDIRECT,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    }
  }
}

const msalInstance = new msal.PublicClientApplication(getMsalConfig());
export { loginRequest, silentRequest, msalInstance };
