import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { IHost } from '@/shared/interface/host.interface';
import { HostModel } from '@/shared/models/host.model';
import type { MutationTree } from 'vuex';
import type { HostState } from './types';

export const mutations: MutationTree<HostState> = {
  setHosts(state, hosts: IHost[]) {
    state.hosts = hosts.map((host) => new HostModel(host));
  },
  setEmitterTypes(state, emitterTypes: string[]) {
    state.emitterTypes = emitterTypes ?? [];
  },
  hasLoadedHosts(state, payload: boolean) {
    state.hostsHasLoaded = payload
  },
  hasLoadedEmitterTypes(state, payload: boolean) {
    state.emittersHasLoaded = payload
  },
  setCrudState(state, crud: ICrudState) {
    state.crudState = crud;
  },
  addHostToState(state, host: IHost) {
    state.hosts = [...state.hosts ?? [], new HostModel(host)];
  },
  updateHostsInState(state, payload: IHost[]) {
    const newState = state.hosts.map((host) => {
      const updatedHost = payload.find((updatedHost) => updatedHost.id === host.id);
      return updatedHost ? new HostModel(updatedHost) : host;
    });
    state.hosts = [...newState]
  },
  removeHostFromState(state, hostId: string) {
    state.hosts = state.hosts.filter((host) => host.id !== hostId);
  },
  clearCrudState(state, payload?: number) {
    if (payload && payload > 0) {
      setTimeout(() => {
        state.crudState = null;
      }, payload);
    } else {
      state.crudState = null;
    }
  }
}