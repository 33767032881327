import { render, staticRenderFns } from "./NumberInputFieldComponent.vue?vue&type=template&id=00530131&scoped=true&"
import script from "./NumberInputFieldComponent.vue?vue&type=script&lang=ts&"
export * from "./NumberInputFieldComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_a7yqy5dhmduz2mxkyxhvyeinou/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00530131",
  null
  
)

export default component.exports