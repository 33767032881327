import type { IApplicationError } from './error-code.interface';

export class ErrorMap extends Map<number, string> {
  constructor(errors: IApplicationError[]) {
    super();
    for (const error of errors) {
      this.set(error.statusCode, error.errorMessage);
    }
    this.set(401, 'GENERAL.ERROR.USER_NOT_AUTHENTICATED');
    this.set(500, 'GENERAL.ERROR.INTERNAL_SERVER_ERROR');
  }
}
