import i18n from '@/i18n';
import { WorkflowStatusEnum } from '@/shared/enum/workflow-status.enum';

import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { Status } from '@/shared/interface/project-status.interface';
import type { Workflow } from '@/shared/interface/workflow.interface';
import type { GetterTree } from 'vuex';
import type { RootState } from '../types';
import type { WorkflowState } from './types';

export enum WorkflowGetters {
  getWorkflows = 'workflows/getWorkflows',
  isSavingWorkflow = 'workflows/isSavingWorkflows',
  hasLoadedWorkflows = 'workflows/hasLoadedWorkflows',
  workflowCrudState = 'workflows/getWorkflowCrudState',
  workflowStatusList = 'workflows/getWorkflowStatusList'
}

export const getters: GetterTree<WorkflowState, RootState> = {
  getWorkflows(state): Workflow[] {
    return state.workflows;
  },
  getIsSavingWorkflows(state): boolean {
    return state.crudState?.loading ?? false;
  },
  hasLoadedWorkflows(state): boolean {
    return state.workflowsHasLoaded;
  },
  getWorkflowCrudState(state): ICrudState | null {
    return state.crudState
  },
  getWorkflowStatusList(): Status[] {
    return [
      { value: WorkflowStatusEnum.None, text: i18n.t('WORKFLOW_STATUS.None').toString() },
      { value: WorkflowStatusEnum.WaitingForYou, text: i18n.t('WORKFLOW_STATUS.WaitingForYou').toString() },
      { value: WorkflowStatusEnum.WaitingForManager, text: i18n.t('WORKFLOW_STATUS.WaitingForManager').toString() },
      { value: WorkflowStatusEnum.WaitingForOtherStep, text: i18n.t('WORKFLOW_STATUS.WaitingForOtherStep').toString() },
      { value: WorkflowStatusEnum.Working, text: i18n.t('WORKFLOW_STATUS.Working').toString() },
      { value: WorkflowStatusEnum.Completed, text: i18n.t('WORKFLOW_STATUS.Completed').toString() },
      { value: WorkflowStatusEnum.Paused, text: i18n.t('WORKFLOW_STATUS.Paused').toString() },
      { value: WorkflowStatusEnum.Cancelled, text: i18n.t('WORKFLOW_STATUS.Cancelled').toString() },
    ]
  }
}