import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { alertsState } from './alerts';
import { groupState } from './group';
import { hostState } from './host';
import { projectState } from './project';
import { tagState } from './tag';
import { userState } from './user';
import { workflowState } from './workflow';

import type { RootState } from './types';
//import Account from "@/store/account";
//https://stackoverflow.com/questions/53807294/how-is-the-correct-way-to-work-with-vuex-and-typescript

Vue.use(Vuex);
const store: StoreOptions<RootState> = {
  modules: {
    hosts: hostState,
    projects: projectState,
    user: userState,
    alerts: alertsState,
    tags: tagState,
    groups: groupState,
    workflows: workflowState
  }
}

export default new Vuex.Store<RootState>(store);

