import { GroupService } from '@/services/group.service';
import { AlertType } from '@/shared/interface/alert.interface';

import { AlertActions } from '../alerts/actions';

import type { ActionTree } from 'vuex';
import type { RootState } from '../types';
import type { GroupState } from './types';
import type { Group } from '@/shared/interface/group.interface';

export enum GroupActions {
  getGroups = 'groups/getGroups',
  addGroup = 'groups/addGroup',
  updateGroup = "groups/updateGroup",
  deleteGroup = 'groups/deleteGroup',
  clearGroupCrudState = 'groups/clearGroupCrudState',
}

export const actions: ActionTree<GroupState, RootState> = {
  async getGroups({ dispatch, commit }): Promise<void> {
    try {
      const groups: Group[] = await new GroupService().getGroups();
      commit("setGroups", groups);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true });
      commit("setGroups", []);
    }
    commit('hasLoadedGroups', true);
  },

  async deleteGroup({ dispatch, commit }, group: Group): Promise<void> {
    commit('setCrudState', { loading: true, success: null }); try {
      await new GroupService().deleteGroup(group.id);
      dispatch(AlertActions.QueueTost, { text: "GROUP.CRUD.DELETE_SUCCESS", type: AlertType.Success }, { root: true })
      commit('setCrudState', { loading: false, success: true });
      commit('clearGroupFromState', group);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    }
  },

  async addGroup({ dispatch, commit }, group: Group): Promise<void> {
    commit('setCrudState', { loading: true, success: null });
    try {
      const newGroup = await new GroupService().createGroup(group);
      dispatch(AlertActions.QueueTost, { text: "GROUP.CRUD.CREATE_SUCCESS", type: AlertType.Success }, { root: true })
      commit('setCrudState', { loading: false, success: true });
      commit('addGroupToState', newGroup);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, success: false });
    }
  },

  async updateGroup({ dispatch, commit }, group: Group): Promise<void> {
    commit('setCrudState', { loading: true, success: null });
    try {
      const updatedGroup = await new GroupService().updateGroup(group);
      dispatch(AlertActions.QueueTost, { text: "GROUP.CRUD.UPDATE_SUCCESS", type: AlertType.Success }, { root: true })
      commit('setCrudState', { loading: false, success: true });
      commit('updateGroupsInState', [updatedGroup]);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, success: false });
    }
  },

  clearGroupCrudState({ commit }): void {
    commit('clearCrudState');
  }
}