import http from '@/axios-config';
import { ErrorHandler } from '@/shared/errors/error-handler';
import { ErrorMap } from '@/shared/errors/error-map.model';

import { groupApiVersion } from './api.version';

import type { Group } from '@/shared/interface/group.interface';
export class GroupService extends ErrorHandler {

  public async deleteGroup(id: string): Promise<any> {
    try {
      const { data } = await http.delete(`${groupApiVersion}/CustomerGroup/DeleteCustomerGroup/${id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "GROUP.CRUD.MISSING_RIGHTS_DELETE",
            statusCode: 403
          },
          {
            errorMessage: "GROUP.CRUD.NOT_FOUND",
            statusCode: 404
          }
        ]))
      return null;
    }
  }

  public async createGroup(group: Group): Promise<Group | null> {
    try {
      const { data } = await http.post(`${groupApiVersion}/CustomerGroup/AddCustomerGroup`, group);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "GROUP.CRUD.MISSING_RIGHTS_CREATE",
            statusCode: 403
          }
        ]))
      return null;
    }
  }

  public async updateGroup(group: Group): Promise<Group> {
    try {
      const { data } = await http.put(`${groupApiVersion}/CustomerGroup/UpdateCustomerGroup`, group);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "GROUP.CRUD.NOT_FOUND",
            statusCode: 404
          },
          {
            errorMessage: "GROUP.CRUD.MISSING_RIGHTS_UPDATE",
            statusCode: 403
          }
        ]))
      return null;
    }
  }

  public async getGroups(): Promise<Group[]> {
    try {
      const { data } = await http.get(`${groupApiVersion}/CustomerGroup/GetAllCustomerGroups`);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "GROUP.CRUD.MISSING_RIGHTS_READ",
            statusCode: 403
          }
        ]))
      return [];
    }
  }
}