import { Unit } from '../enum/unit.enum';

import type { IResourceType } from '../interface/resource-type.interface';

export class ResourceType implements IResourceType {
  constructor(
    public value: number = null,
    public unit: Unit = Unit.None,
    public unitInText = '',
    public resource: string = null,
  ) { }
}


export class EmissionResourceType implements IResourceType {
  constructor(
    public value: number = null,
    public unit: Unit = Unit.Tonne,
    public unitInText = '',
    public resource = 'Co2'
  ) { }
}