import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { Workflow } from '@/shared/interface/workflow.interface';
import type { MutationTree } from 'vuex';
import type { WorkflowState } from './types';


export const mutations: MutationTree<WorkflowState> = {
  setWorkflows(state, workflows: Workflow[]) {
    state.workflows = workflows ?? [];
  },
  hasLoadedWorkflows(state, payload: boolean) {
    state.workflowsHasLoaded = payload
  },
  setCrudState(state, crud: ICrudState) {
    state.crudState = crud;
  },
  clearCrudState(state, payload?: number) {
    if (payload && payload > 0) {
      setTimeout(() => {
        state.crudState = null;
      }, payload);
    } else {
      state.crudState = null;
    }
  },
  clearWorkflowFromState(state, workflow: Workflow) {
    state.workflows = state.workflows.filter((stateState) => stateState.id !== workflow.id);
  },
  addWorkflowToState(state, workflow: Workflow) {
    state.workflows = [...state.workflows ?? [], workflow];
  },
  updateWorkflowsInState(state, workflows: Workflow[]) {
    const newState = state.workflows.map((workflow) => {
      const updatedWorkflow = workflows.find((updatedWorkflow) => updatedWorkflow.id === workflow.id);
      return updatedWorkflow ? updatedWorkflow : workflow;
    });
    state.workflows = [...newState]
  }
}