import http, { isCancel } from '@/axios-config';
import { ErrorHandler } from '@/shared/errors/error-handler';
import { ErrorMap } from '@/shared/errors/error-map.model';
import { HostModel } from '@/shared/models/host.model';

import { hostApiVersion } from './api.version';

import type { IHost } from '@/shared/interface/host.interface';
export class HostService extends ErrorHandler {
  public async deleteHost(hostId: string): Promise<any> {
    try {
      const { data } = await http.delete(`${hostApiVersion}/Host/DeleteHost/${hostId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "HOST.CRUD.MISSING_RIGHTS_DELETE",
            statusCode: 403
          },
          {
            errorMessage: "HOST.CRUD.NOT_FOUND",
            statusCode: 404
          }
        ]))
      return null;
    }
  }

  public async addHost(host: HostModel): Promise<HostModel | null> {
    try {

      const { data } = await http.post(`${hostApiVersion}/Host/AddHost`, host);
      return new HostModel(data);
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "HOST.CRUD.MISSING_RIGHTS_CREATE",
            statusCode: 403
          }
        ]))
      return null;
    }

  }

  public async updateHost(host: HostModel): Promise<HostModel | null> {
    try {
      const { data } = await http.put(`${hostApiVersion}/Host/UpdateHost`, host);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "HOST.CRUD.NOT_FOUND",
            statusCode: 404
          },
          {
            errorMessage: "HOST.CRUD.MISSING_RIGHTS_UPDATE",
            statusCode: 403
          }
        ]))
      return null;
    }
  }

  public async getAllHosts(impersonationId?: string): Promise<IHost[]> {
    try {
      const { data } = await http.get(`${hostApiVersion}/Host/GetAllHosts`, {
        params: {
          'ImpersonationId': impersonationId
        }
      });
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "HOST.CRUD.MISSING_RIGHTS_READ",
            statusCode: 403
          }
        ]))
      return [];
    }
  }

  public async getHost(hostId: string, signal?: AbortSignal): Promise<IHost> {
    try {
      const { data } = await http.get(`${hostApiVersion}/Host/GetHost/${hostId}`,
        {
          signal: signal
        });
      return data;
    } catch (error: any) {
      if (!isCancel(error)) {
        this.handleError(error,
          new ErrorMap([
            {
              errorMessage: "HOST.CRUD.MISSING_RIGHTS_READ",
              statusCode: 403
            },
            {
              errorMessage: "HOST.CRUD.NOT_FOUND",
              statusCode: 404
            }
          ]))
      }
      return null;
    }
  }

  public getHostShortNameOrName(hostId: string, hosts: HostModel[]): string {
    const host = hosts.find((host) => host.id === hostId);
    return host?.shortName && host?.shortName !== ""
      ? host?.shortName
      : host?.name;
  }

  public getHostname(hostId: string, hosts: HostModel[]): string {
    const host = hosts.find((host) => host.id === hostId);
    return host?.name ?? "";
  }
}