import i18n from '@/i18n';
import { HostStatusEnum } from '@/shared/enum/host-status.enum';
import { translatedEmitterTypes } from '@/shared/enum/translated-enum';

import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { IEnumTranslation } from '@/shared/interface/enum-translation.interface';
import type { IActive } from '@/shared/interface/host-active.interface';
import type { Status } from '@/shared/interface/project-status.interface';
import type { HostModel } from '@/shared/models/host.model';
import type { GetterTree } from 'vuex';
import type { RootState } from '../types';
import type { HostState } from './types';

export enum HostGetters {
  getHosts = 'hosts/getHosts',
  getEmitterTypes = 'hosts/getEmitterTypes',
  isSavingHost = 'hosts/isSavingHost',
  hasLoadedHosts = 'hosts/hasLoadedHosts',
  hasLoadedEmitters = 'hosts/hasLoadedEmitters',
  hostCrudState = 'hosts/getHostCrudState',
  hostStatusList = 'hosts/getHostStatusList',
  hostActiveList = 'hosts/getActiveList'
}

export const getters: GetterTree<HostState, RootState> = {
  getHosts(state): HostModel[] {
    return state.hosts;
  },
  getEmitterTypes(): IEnumTranslation[] {
    return translatedEmitterTypes();
  },
  getIsSavingHosts(state): boolean {
    return state.crudState?.loading ?? false;
  },
  hasLoadedHosts(state): boolean {
    return state.hostsHasLoaded;
  },
  hasLoadedEmitters(state): boolean {
    return state.emittersHasLoaded;
  },
  getHostCrudState(state): ICrudState | null {
    return state.crudState
  },
  getHostStatusList(): Status[] {
    return [
      { value: HostStatusEnum.None, text: i18n.t('HOST_STATUS.None').toString() },
      { value: HostStatusEnum.NotContacted, text: i18n.t('HOST_STATUS.NotContacted').toString() },
      { value: HostStatusEnum.Contacted, text: i18n.t('HOST_STATUS.Contacted').toString() },
      { value: HostStatusEnum.NotInterested, text: i18n.t('HOST_STATUS.NotInterested').toString() },
      { value: HostStatusEnum.Interested, text: i18n.t('HOST_STATUS.Interested').toString() },
      { value: HostStatusEnum.Screening, text: i18n.t('HOST_STATUS.Screening').toString() }
    ]
  },
  getActiveList(): IActive[] {
    return [
      { value: false, text: i18n.t('GENERAL.POTENTIAL').toString() },
      { value: true, text: i18n.t('GENERAL.ACTIVE').toString() }
    ]
  }
}