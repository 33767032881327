
import type { ErrorMap } from './error-map.model';
import { ApplicationError, SomethingWentWrong } from './application-errors.model';

export abstract class ErrorHandler {
  /**
   *
   * @param error The error that was caught from a call to backend
   * @param errorMap The errors that will be handled in
   */
  public handleError(error: Error, errorMap: ErrorMap): void {
    this.dispatchError(this.retrieveStatusCode(error), errorMap);
  }

  private dispatchError(errorCode: number, errorMap: ErrorMap): void {
    if (errorMap.has(errorCode)) {
      throw new ApplicationError(errorMap.get(errorCode) ?? 'Application error', errorCode);
    } else {
      throw new SomethingWentWrong('Something went wrong', errorCode);
    }
  }

  private retrieveStatusCode(error: any): number {
    try {
      return error.response.status;
    } catch (e) {
      return -1;
    }
  }
}
