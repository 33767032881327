export enum Unit {
  None = "None",
  Kg = "Kg",
  Tonne = "Tonne",
  Liter = "Liter",
  Kwh = "Kwh",
  Mwh = "Mwh",
  Gwh = "Gwh",
  CubicMeter = "CubicMeter",
  Btu = "Btu"
}