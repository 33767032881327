import { HostStatusEnum } from '../enum/host-status.enum';
import { Address } from './address.model';
import { Contact } from './contact.model';
import { Emitter } from './emitter.model';
import { EmissionResourceType } from './resource-type.model';

import type { Unit } from '../enum/unit.enum';
import type { IAddress } from '../interface/address.interface';
import type { IContact } from '../interface/contact.interface';
import type { IEmitter } from '../interface/emitter.interface';
import type { IHost, IHostState } from '../interface/host.interface';

export class Host implements IHost {
  constructor(
    public id: string = '',
    public name: string = '',
    public hostIds: string[] = [],
    public projectIds: string[] = [],
    public screeningDate: string = '',
    public status: HostStatusEnum = HostStatusEnum.None,
    public contact: IContact = new Contact(),
    public address: IAddress = new Address(null),
    public emitters: IEmitter[] = [],
    public screeningState: IHostState = new HostState(),
    public potentialState: IHostState = new HostState(),
    public active: boolean,
  ) { }
}

class HostState implements IHostState {
  constructor(
    public emissionPerYear = new EmissionResourceType(),
    public costPerYear: number = 0,
  ) { }
}

export class HostModel implements IHost {
  id: string;
  name: string;
  description?: string;
  hostIds: string[];
  projectIds: string[];
  screeningDate: string;
  status: HostStatusEnum;
  contact: IContact;
  address: IAddress;
  emitters: Emitter[];
  screeningState: IHostState;
  potentialState: IHostState;
  active: boolean;
  shortName?: string;
  constructor(host?: IHost) {
    if (host) {
      this.id = host.id;
      this.name = host.name;
      this.description = host.description;
      this.hostIds = host.hostIds;
      this.projectIds = host.projectIds;
      this.screeningDate = host.screeningDate;
      this.status = host.status;
      this.contact = host.contact;
      this.address = host.address;
      this.emitters = host.emitters?.map(emitter => new Emitter(emitter));
      this.screeningState = host.screeningState;
      this.potentialState = host.potentialState;
      this.active = host.active;
      this.shortName = host.shortName;
    } else {
      this.status = HostStatusEnum.None;
      this.contact = new Contact();
      this.address = new Address(null);
      this.emitters = [];
      this.screeningState = new HostState();
      this.potentialState = new HostState();
      this.active = false;
    }
  }


  public get contactName(): string {
    return this.contact?.name;
  }

  public set contactName(value: string) {
    this.contact.name = value;
  }

  public get contactEmail(): string {
    return this.contact?.email;
  }

  public set contactEmail(value: string) {
    this.contact.email = value;
  }

  public get addressStreet(): string {
    return this.address?.street;
  }

  public set addressStreet(value: string) {
    this.address.street = value;
  }

  public get addressCity(): string {
    return this.address?.city;
  }

  public set addressCity(value: string) {
    this.address.city = value;
  }

  public get addressPostalCode(): string {
    return this.address?.postalCode;
  }

  public set addressPostalCode(value: string) {
    this.address.postalCode = value;
  }

  public get addressCountry(): string {
    return this.address?.country;
  }

  public set addressCountry(value: string) {
    this.address.country = value;
  }

  public get potentialEmissionPerYear(): EmissionResourceType {
    return this.potentialState?.emissionPerYear;
  }

  public set potentialEmissionPerYear(value: EmissionResourceType) {
    this.potentialState.emissionPerYear = value;
  }

  public get potentialEmissionPerYearValue(): number {
    return this.potentialState?.emissionPerYear?.value;
  }

  public set potentialEmissionPerYearValue(value: number) {
    this.potentialState.emissionPerYear.value = value;
  }

  public get potentialEmissionPerYearUnit(): Unit {
    return this.potentialState?.emissionPerYear?.unit;
  }

  public set potentialEmissionPerYearUnit(value: Unit) {
    this.potentialState.emissionPerYear.unit = value;
  }

  public get potentialCostPerYear(): number {
    return this.potentialState?.costPerYear;
  }

  public set potentialCostPerYear(value: number) {
    this.potentialState.costPerYear = value;
  }

  public get screeningEmissionPerYear(): EmissionResourceType {
    return this.potentialState?.emissionPerYear;
  }

  public set screeningEmissionPerYear(value: EmissionResourceType) {
    this.potentialState.emissionPerYear = value;
  }

  public get screeningEmissionCostPerYear(): number {
    return this.screeningState?.costPerYear;
  }

  public set screeningEmissionCostPerYear(value: number) {
    this.screeningState.costPerYear = value;
  }

  public get screeningEmissionPerYearValue(): number {
    return this.screeningState?.emissionPerYear?.value;
  }

  public set screeningEmissionPerYearValue(value: number) {
    this.screeningState.emissionPerYear.value = value;
  }

  public get screeningEmissionPerYearUnit(): Unit {
    return this.screeningState?.emissionPerYear?.unit;
  }

  public set screeningEmissionPerYearUnit(value: Unit) {
    this.screeningState.emissionPerYear.unit = value;
  }

  public get screeningEmissionPerYearResource(): string {
    return this.screeningState?.emissionPerYear?.resource;
  }

  public set screeningEmissionPerYearResource(value: string) {
    this.screeningState.emissionPerYear.resource = value;
  }

  public get screeningCostPerYear(): number {
    return this.potentialState?.costPerYear;
  }

  public set screeningCostPerYear(value: number) {
    this.potentialState.costPerYear = value;
  }
}