import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { UserRow } from '@/shared/interface/user-row.interface';
import type { IUserSettings } from '@/shared/interface/user-settings.interface';
import type { AuthenticationResult } from '@azure/msal-browser';
import type { MutationTree } from 'vuex';
import type { UserState } from './types';

export const mutations: MutationTree<UserState> = {
  hasLoadedUsers(state, payload: boolean) {
    state.usersHasLoaded = payload;
  },
  setLoggedIn(state, newStatus: boolean) {
    state.isLoggedIn = newStatus;
  },
  setLoggingIn(state, newStatus: boolean) {
    state.isLoggingIn = newStatus;
  },
  setUsers(state, users: UserRow[]) {
    state.users = users;
  },
  addUserToStore(state, user: UserRow) {
    state.users = [...state.users ?? [], user];
  },
  removeUserFromState(state, userId: string) {
    state.users = state.users.filter((user) => user.id !== userId);
  },
  updateUserInStore(state, updatedUsers: UserRow[]) {
    const newState = state.users.map((user) => {
      const updatedUser = updatedUsers.find((updatedUser) => updatedUser.id === user.id);
      return updatedUser ? updatedUser : user;
    });
    state.users = [...newState]
  },
  setMsalAuth(state, value: AuthenticationResult) {
    state.msalAuth = value;
  },
  setCrudState(state, crud: ICrudState) {
    state.crudState = crud;
  },
  clearCrudState(state, payload?: number) {
    if (payload && payload > 0) {
      setTimeout(() => {
        state.crudState = null;
      }, payload);
    } else {
      state.crudState = null;
    }
  },
  setUser(state, user: UserRow) {
    state.myUser = user;
  },
  hideAdminFeatures(state, payload: boolean) {
    if (state.userSettings) {
      state.userSettings.hideAdminFeatures = payload;
    } else {
      const userSettings: IUserSettings = {
        hideAdminFeatures: payload
      }
      state.userSettings = userSettings;
    }
  },
  setUserSettings(state, payload: IUserSettings) {
    state.userSettings = payload;
  }
}