import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { Tag } from '@/shared/interface/tag.interface';
import type { GetterTree } from 'vuex';
import type { RootState } from '../types';
import type { TagState } from './types';

export enum TagGetters {
  getTags = 'tags/getTags',
  isSavingTag = 'tags/isSavingTags',
  hasLoadedTags = 'tags/hasLoadedTags',
  tagCrudState = 'tags/getTagCrudState',
  getTagVisibilityTypes = 'tags/getTagVisibilityTypes',
}

export const getters: GetterTree<TagState, RootState> = {
  getTags(state): Tag[] {
    return state.tags;
  },
  getIsSavingTags(state): boolean {
    return state.crudState?.loading ?? false;
  },
  hasLoadedTags(state): boolean {
    return state.tagsHasLoaded;
  },
  getTagCrudState(state): ICrudState | null {
    return state.crudState
  },
  getTagVisibilityTypes(): string[] {
    return ["Public", "Private"];
  }
}