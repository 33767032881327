const projectApiVersion = 'v2';
const hostApiVersion = 'v2';
const tagApiVersion = 'v1';
const userApiVersion = 'v1';
const groupApiVersion = 'v1';
const workflowApiVersion = 'v1';

export {
  projectApiVersion,
  hostApiVersion,
  tagApiVersion,
  userApiVersion,
  groupApiVersion,
  workflowApiVersion
}