import http from '@/axios-config';
import { ErrorHandler } from '@/shared/errors/error-handler';
import { ErrorMap } from '@/shared/errors/error-map.model';
import type { Tag } from '@/shared/interface/tag.interface';
import { tagApiVersion } from './api.version';

export class TagService extends ErrorHandler {

  public async deleteTag(tagId: string): Promise<any> {
    try {
      const { data } = await http.delete(`${tagApiVersion}/Tag/Delete/${tagId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "TAG.CRUD.MISSING_RIGHTS_DELETE",
            statusCode: 403
          },
          {
            errorMessage: "TAG.CRUD.NOT_FOUND",
            statusCode: 404
          }
        ]))
      return null;
    }
  }

  public async createTag(tag: Tag): Promise<Tag | null> {
    try {
      const { data } = await http.post(`${tagApiVersion}/Tag/Add`, tag);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "TAG.CRUD.MISSING_RIGHTS_CREATE",
            statusCode: 403
          }
        ]))
      return null;
    }

  }

  public async updateTag(tag: Tag): Promise<Tag> {
    try {
      const { data } = await http.put(`${tagApiVersion}/Tag/Update`, tag);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "TAG.CRUD.NOT_FOUND",
            statusCode: 404
          },
          {
            errorMessage: "TAG.CRUD.MISSING_RIGHTS_UPDATE",
            statusCode: 403
          }
        ]))
      return null;
    }
  }

  public async getTags(): Promise<Tag[]> {
    try {
      const { data } = await http.get(`${tagApiVersion}/Tag/GetAllTags`);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "TAG.CRUD.MISSING_RIGHTS_READ",
            statusCode: 403
          }
        ]))
      return [];
    }
  }
}