import http from '@/axios-config';
import { ErrorHandler } from '@/shared/errors/error-handler';
import { ErrorMap } from '@/shared/errors/error-map.model';

import { workflowApiVersion } from './api.version';

import type { IWorkflowToStart } from '@/shared/interface/workflow-to-start.interface';
import type { Workflow } from '@/shared/interface/workflow.interface';
import { emptyGuid } from '@/utils/guid.utils';
import type { AxiosResponse } from 'axios';
export class WorkflowService extends ErrorHandler {

  public async deleteWorkflow(id: string): Promise<any> {
    try {
      const { data } = await http.delete(`${workflowApiVersion}/Workflow/DeleteWorkflow/${id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "WORKFLOW.CRUD.MISSING_RIGHTS_DELETE",
            statusCode: 403
          },
          {
            errorMessage: "WORKFLOW.CRUD.NOT_FOUND",
            statusCode: 404
          }
        ]))
      return null;
    }
  }

  public async updateWorkflow(workflow: Workflow): Promise<Workflow> {
    try {
      const { data } = await http.put(`${workflowApiVersion}/Workflow/UpdateWorkflow`, workflow);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "WORKFLOW.CRUD.NOT_FOUND",
            statusCode: 404
          },
          {
            errorMessage: "WORKFLOW.CRUD.MISSING_RIGHTS_UPDATE",
            statusCode: 403
          }
        ]))
      return null;
    }
  }

  public async getWorkflows(): Promise<Workflow[]> {
    try {
      const { data } = await http.get(`${workflowApiVersion}/Workflow/GetAllWorkflows`);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "WORKFLOW.CRUD.MISSING_RIGHTS_READ",
            statusCode: 403
          }
        ]))
      return [];
    }
  }

  public async startWorkFlow(workflow: IWorkflowToStart): Promise<Workflow> {
    if (!workflow.hostId) {
      workflow.hostId = emptyGuid();
    }
    if (!workflow.projectId) {
      workflow.projectId = emptyGuid();
    }
    try {
      const result = await http.post<IWorkflowToStart, AxiosResponse<Workflow>>(`${workflowApiVersion}/Workflow/StartWorkflow`, workflow, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const createdWorkflow: Workflow = result?.data;
      return createdWorkflow;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "GROUP.CRUD.MISSING_RIGHTS_CREATE",
            statusCode: 403
          }
        ]))
      return null;
    }
  }
}