import axios from 'axios';
import { acquireToken } from './utils/login.utils';

const axiosInstance = axios.create({
  baseURL: window.VUE_APP_BASE_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(async (config: any) => {
  const authResult = await acquireToken();
  config.headers.common = {
    ...config.headers.common,
    Authorization: `Bearer ${authResult?.idToken}`,
  };
  return config;
}, (error) => Promise.reject(error),
);

export const isCancel = (error: any) => {
  return axios.isCancel(error);
}

export default axiosInstance;
