import i18n from '@/i18n';

import { ProjectStatusEnum } from '../enum/project-status.enum';
import { Unit } from '../enum/unit.enum';
import { Address } from './address.model';
import { Contact } from './contact.model';
import { EmissionResourceType, ResourceType } from './resource-type.model';

import type { ProjectTypesEnum } from '../enum/project-types.enum';
import type { IAddress } from '../interface/address.interface';
import type { IContact } from '../interface/contact.interface';
import type { IAdmin, IBaseState, ICurrentState, IFinancing, IGeneralState, IProject, IProjectPlan } from '../interface/project.interface';
import { getUnitByProjectType } from '@/utils/unit-types.utils';
export abstract class BaseState implements IBaseState {
  constructor(
    public productionPerYear?: ResourceType,
    public emissionPerYear?: ResourceType
  ) { }
}

export class CurrentState extends BaseState {
  public consumptionPerYear?: ResourceType;
  public costPerYear: number;
  public emissionPerConsumptionUnit: number;
  constructor(state?: ICurrentState) {
    super(
      new ResourceType(
        state?.productionPerYear?.value,
        state?.productionPerYear?.unit,
        state?.productionPerYear?.unitInText,
        state?.productionPerYear?.resource) ??
      new ResourceType(0, Unit.None, '', null),
      new EmissionResourceType(
        state?.emissionPerYear?.value,
        state?.emissionPerYear?.unit,
        state?.emissionPerYear?.unitInText,
        state?.emissionPerYear?.resource) ??
      new EmissionResourceType());
    this.consumptionPerYear =
      new ResourceType(
        state?.consumptionPerYear?.value,
        state?.consumptionPerYear?.unit,
        state?.consumptionPerYear?.unitInText,
        state?.consumptionPerYear?.resource) ??
      new ResourceType(0, Unit.None, '', null);
    this.costPerYear = state?.costPerYear;
    this.emissionPerConsumptionUnit = state?.emissionPerConsumptionUnit;
  }
}

export class GeneralState extends BaseState {
  public reductionPerYear: ResourceType;
  public totalInvestment?: number;
  public totalReturnOnInvestment?: number;
  public opexPerYear?: number;
  public savingsPerYear?: number;
  public savingsPerYearAuto?: number;
  constructor(state?: IGeneralState) {
    super(
      new ResourceType(
        state?.productionPerYear?.value,
        state?.productionPerYear?.unit,
        state?.productionPerYear?.unitInText,
        state?.productionPerYear?.resource) ??
      new ResourceType(null, Unit.None, '', null),
      new EmissionResourceType(
        state?.emissionPerYear?.value,
        state?.emissionPerYear?.unit,
        state?.emissionPerYear?.unitInText,
        state?.emissionPerYear?.resource) ??
      new EmissionResourceType());
    this.reductionPerYear =
      new ResourceType(
        state?.reductionPerYear?.value,
        state?.reductionPerYear?.unit,
        state?.reductionPerYear?.unitInText,
        state?.reductionPerYear?.resource) ??
      new ResourceType(null, Unit.None, '');
    this.totalInvestment = state?.totalInvestment;
    this.totalReturnOnInvestment = state?.totalReturnOnInvestment;
    this.opexPerYear = state?.opexPerYear;
    this.savingsPerYear = state?.savingsPerYear;
    this.savingsPerYearAuto = state?.savingsPerYearAuto;
  }
}

export class ProjectPlan implements IProjectPlan {
  public startDate: string;
  public estimatedCompletion: string;
  public actualCompletion: string;
  constructor(state?: IProjectPlan) {
    this.startDate = state?.startDate ?? null;
    this.estimatedCompletion = state?.estimatedCompletion ?? null;
    this.actualCompletion = state?.actualCompletion ?? null;
  }
}

export class Financing implements IFinancing {
  public financingFee: number;
  public durationYears: number;
  public financedPercentage: number;
  public internalRateOfReturn: number;
  public investor: string;
  public residualValue: number;
  constructor(state?: IFinancing) {
    this.financingFee = state?.financingFee;
    this.durationYears = state?.durationYears;
    this.financedPercentage = state?.financedPercentage;
    this.internalRateOfReturn = state?.internalRateOfReturn;
    this.investor = state?.investor;
    this.residualValue = state?.residualValue;
  }
}


export class Project implements IProject {
  id?: string;
  hostId?: string;
  projectName?: string;
  description?: string;
  status: ProjectStatusEnum;
  projectType?: string;
  rating?: number;
  co2Rating?: number;
  financialRating?: number;
  netPresentQuote?: number;
  netPresentValue?: number;
  enabledBy?: string;
  projectUnit: Unit
  projectUnitText?: string;
  currentState?: CurrentState;
  potentialOutcome?: GeneralState;
  actualOutcome?: GeneralState;
  projectPlan?: ProjectPlan;
  contact?: IContact;
  address?: IAddress;
  financing?: IFinancing;
  admin?: IAdmin;
  created?: string;

  constructor(project?: IProject) {
    if (project) {
      this.id = project.id;
      this.hostId = project.hostId;
      this.projectName = project.projectName;
      this.description = project.description;
      this.status = project.status;
      this.projectType = project.projectType;
      this.rating = project.rating;
      this.co2Rating = project.co2Rating;
      this.financialRating = project.financialRating;
      this.netPresentQuote = project.netPresentQuote;
      this.netPresentValue = project.netPresentValue;
      this.enabledBy = project.enabledBy;
      this.projectUnit = project.projectUnit;
      this.projectUnitText = project.projectUnitText;
      this.currentState = new CurrentState(project.currentState);
      this.potentialOutcome = new GeneralState(project.potentialOutcome);
      this.actualOutcome = new GeneralState(project.actualOutcome);
      this.projectPlan = new ProjectPlan(project.projectPlan);
      this.contact = new Contact(project.contact);
      this.address = new Address(project.address);
      this.financing = new Financing(project.financing);
      this.admin = {
        responsible: project.admin?.responsible,
        comment: project.admin?.comment,
      }
      this.created = project.created;
    } else {
      this.status = ProjectStatusEnum.Idea;
      this.currentState = new CurrentState();
      this.potentialOutcome = new GeneralState();
      this.actualOutcome = new GeneralState();
      this.projectPlan = new ProjectPlan();
      this.contact = new Contact();
      this.address = new Address();
      this.financing = new Financing();
      this.admin = {
        responsible: null,
        comment: null,
      }
    }
  }

  public get projectUnitInText(): string {
    return this.projectUnitText;
  }
  public set projectUnitInText(value: string) {
    this.projectUnitText = value;
    this.potentialOutcome.productionPerYear.unitInText = value;
    this.actualOutcome.productionPerYear.unitInText = value;
  }

  public get projectTypeInText(): string {
    return this.projectType;
  }
  public set projectTypeInText(value: string) {
    this.projectType = value;
    this.potentialOutcome.productionPerYear.resource = value;
    this.actualOutcome.productionPerYear.resource = value;
    this.projectUnitInText = i18n.t("UNIT." + getUnitByProjectType(value as ProjectTypesEnum).toString()).toString();
  }


  // current state resource type getters
  public get currentStateConsumptionPerYear(): ResourceType {
    return this.currentState.consumptionPerYear;
  }
  public set currentStateConsumptionPerYear(value: ResourceType) {
    this.currentState.consumptionPerYear = value;
  }
  public get currentStateProductionPerYear(): ResourceType {
    return this.currentState.productionPerYear;
  }
  public set currentStateProductionPerYear(value: ResourceType) {
    this.currentState.productionPerYear = value;
  }
  public get currentStateEmissionPerYear(): ResourceType {
    return this.currentState.emissionPerYear;
  }
  public set currentStateEmissionPerYear(value: ResourceType) {
    this.currentState.emissionPerYear = value;
  }

  // potential outcome resource type getters
  public get potentialOutcomeProductionPerYear(): ResourceType {
    return this.potentialOutcome.productionPerYear;
  }
  public set potentialOutcomeProductionPerYear(value: ResourceType) {
    this.potentialOutcome.productionPerYear = value;
  }
  public get potentialOutcomeEmissionPerYear(): ResourceType {
    return this.potentialOutcome.emissionPerYear;
  }
  public set potentialOutcomeEmissionPerYear(value: ResourceType) {
    this.potentialOutcome.emissionPerYear = value;
  }
  public get potentialOutcomeReductionPerYear(): ResourceType {
    return this.potentialOutcome.reductionPerYear;
  }
  public set potentialOutcomeReductionPerYear(value: ResourceType) {
    this.potentialOutcome.reductionPerYear = value;
  }

  // actual outcome resource type getters
  public get actualOutcomeProductionPerYear(): ResourceType {
    return this.actualOutcome.productionPerYear;
  }
  public set actualOutcomeProductionPerYear(value: ResourceType) {
    this.actualOutcome.productionPerYear = value;
  }
  public get actualOutcomeEmissionPerYear(): ResourceType {
    return this.actualOutcome.emissionPerYear;
  }
  public set actualOutcomeEmissionPerYear(value: ResourceType) {
    this.actualOutcome.emissionPerYear = value;
  }
  public get actualOutcomeReductionPerYear(): ResourceType {
    return this.actualOutcome.reductionPerYear;
  }
  public set actualOutcomeReductionPerYear(value: ResourceType) {
    this.actualOutcome.reductionPerYear = value;
  }



  // current getter and setters
  public get currentCostPerYear(): number {
    return this.currentState.costPerYear;
  }
  public set currentCostPerYear(value: number) {
    this.currentState.costPerYear = value;
  }
  public get currentEmissionPerConsumptionUnit(): number {
    return this.currentState.emissionPerConsumptionUnit;
  }
  public set currentEmissionPerConsumptionUnit(value: number) {
    this.currentState.emissionPerConsumptionUnit = value;
  }
  // current state production getters and setters
  public get currentStateProductionPerYearUnit(): Unit {
    return this.currentStateProductionPerYear.unit;
  }
  public set currentStateProductionPerYearUnit(value: Unit) {
    this.currentStateProductionPerYear.unit = value;
  }
  public get currentStateProductionPerYearResource(): string {
    return this.currentStateProductionPerYear.resource;
  }
  public set currentStateProductionPerYearResource(value: string) {
    this.currentStateProductionPerYear.resource = value;
  }
  public get currentStateProductionPerYearValue(): number {
    return this.currentStateProductionPerYear.value;
  }
  public set currentStateProductionPerYearValue(value: number) {
    this.currentStateProductionPerYear.value = value;
  }

  // current state emission getters and setters
  public get currentStateEmissionPerYearUnit(): Unit {
    return this.currentStateEmissionPerYear?.unit;
  }
  public set currentStateEmissionPerYearUnit(value: Unit) {
    this.currentStateEmissionPerYear.unit = value;
  }
  public get currentStateEmissionPerYearResource(): string {
    return this.currentStateEmissionPerYear?.resource;
  }
  public set currentStateEmissionPerYearResource(value: string) {
    this.currentStateEmissionPerYear.resource = value;
  }
  public get currentStateEmissionPerYearValue(): number {
    return this.currentStateEmissionPerYear?.value;
  }
  public set currentStateEmissionPerYearValue(value: number) {
    this.currentStateEmissionPerYear.value = value;
  }

  // current state consumption getters and setters
  public get currentStateConsumptionPerYearUnit(): Unit {
    return this.currentStateConsumptionPerYear.unit;
  }
  public set currentStateConsumptionPerYearUnit(value: Unit) {
    this.currentStateConsumptionPerYear.unit = value;
    this.potentialOutcomeReductionPerYearUnit = value;
    this.actualOutcomeReductionPerYearUnit = value;
  }
  public get currentStateConsumptionPerYearResource(): string {
    return this.currentStateConsumptionPerYear.resource;
  }
  public set currentStateConsumptionPerYearResource(value: string) {
    this.currentStateConsumptionPerYear.resource = value;
    this.potentialOutcomeReductionPerYearResource = value;
    this.actualOutcomeReductionPerYearResource = value;
  }
  public get currentStateConsumptionPerYearValue(): number {
    return this.currentStateConsumptionPerYear.value;
  }
  public set currentStateConsumptionPerYearValue(value: number) {
    this.currentStateConsumptionPerYear.value = value;
  }

  // potential outcome getter and setters
  public get potentialSavingsPerYear(): number {
    return this.potentialOutcome?.savingsPerYear;
  }
  public set potentialSavingsPerYear(value: number) {
    this.potentialOutcome.savingsPerYear = value;
  }

  public get potentialSavingsPerYearAuto(): number {
    return this.potentialOutcome?.savingsPerYearAuto;
  }

  public get potentialOpexPerYear(): number {
    return this.potentialOutcome.opexPerYear;
  }
  public set potentialOpexPerYear(value: number) {
    this.potentialOutcome.opexPerYear = value;
  }

  public get potentialTotalReturnOnInvestment(): number {
    return this.potentialOutcome.totalReturnOnInvestment;
  }
  public set potentialTotalReturnOnInvestment(value: number) {
    this.potentialOutcome.totalReturnOnInvestment = value;
  }

  public get potentialTotalInvestment(): number {
    return this.potentialOutcome.totalInvestment;
  }
  public set potentialTotalInvestment(value: number) {
    this.potentialOutcome.totalInvestment = value;
  }

  // potential outcome production getters and setters
  public get potentialOutcomeProductionPerYearUnit(): Unit {
    return this.potentialOutcomeProductionPerYear?.unit;
  }
  public set potentialOutcomeProductionPerYearUnit(value: Unit) {
    this.potentialOutcomeProductionPerYear.unit = value;
  }
  public get potentialOutcomeProductionPerYearUnitInText(): string {
    return this.potentialOutcomeProductionPerYear?.unitInText;
  }
  public set potentialOutcomeProductionPerYearUnitInText(value: string) {
    this.potentialOutcomeProductionPerYear.unitInText = value;
  }
  public get potentialOutcomeProductionPerYearResource(): string {
    return this.potentialOutcomeProductionPerYear?.resource;
  }
  public set potentialOutcomeProductionPerYearResource(value: string) {
    this.potentialOutcomeProductionPerYear.resource = value;
  }
  public get potentialOutcomeProductionPerYearValue(): number {
    return this.potentialOutcomeProductionPerYear?.value;
  }
  public set potentialOutcomeProductionPerYearValue(value: number) {
    this.potentialOutcomeProductionPerYear.value = value;
  }

  // potential outcome emission getters and setters
  public get potentialOutcomeEmissionPerYearUnit(): Unit {
    return this.potentialOutcomeEmissionPerYear?.unit;
  }
  public set potentialOutcomeEmissionPerYearUnit(value: Unit) {
    this.potentialOutcomeEmissionPerYear.unit = value;
  }
  public get potentialOutcomeEmissionPerYearResource(): string {
    return this.potentialOutcomeEmissionPerYear?.resource;
  }
  public set potentialOutcomeEmissionPerYearResource(value: string) {
    this.potentialOutcomeEmissionPerYear.resource = value;
  }
  public get potentialOutcomeEmissionPerYearValue(): number {
    return this.potentialOutcomeEmissionPerYear?.value;
  }
  public set potentialOutcomeEmissionPerYearValue(value: number) {
    this.potentialOutcomeEmissionPerYear.value = value;
  }

  // potential outcome reduction getters and setters
  public get potentialOutcomeReductionPerYearUnit(): Unit {
    return this.potentialOutcomeReductionPerYear.unit;
  }
  public set potentialOutcomeReductionPerYearUnit(value: Unit) {
    this.potentialOutcomeReductionPerYear.unit = value;
  }
  public get potentialOutcomeReductionPerYearResource(): string {
    return this.potentialOutcomeReductionPerYear.resource;
  }
  public set potentialOutcomeReductionPerYearResource(value: string) {
    this.potentialOutcomeReductionPerYear.resource = value;
  }
  public get potentialOutcomeReductionPerYearValue(): number {
    return this.potentialOutcomeReductionPerYear.value;
  }
  public set potentialOutcomeReductionPerYearValue(value: number) {
    this.potentialOutcomeReductionPerYear.value = value;
  }


  // actual outcome getter and setters
  public get actualSavingsPerYear(): number {
    return this.actualOutcome?.savingsPerYear;
  }
  public set actualSavingsPerYear(value: number) {
    this.actualOutcome.savingsPerYear = value;
  }
  public get actualSavingsPerYearAuto(): number {
    return this.actualOutcome?.savingsPerYearAuto;
  }
  public get actualOpexPerYear(): number {
    return this.actualOutcome?.opexPerYear;
  }
  public set actualOpexPerYear(value: number) {
    this.actualOutcome.opexPerYear = value;
  }
  public get actualTotalReturnOnInvestment(): number {
    return this.actualOutcome?.totalReturnOnInvestment;
  }
  public set actualTotalReturnOnInvestment(value: number) {
    this.actualOutcome.totalReturnOnInvestment = value;
  }
  public get actualTotalInvestment(): number {
    return this.actualOutcome?.totalInvestment;
  }
  public set actualTotalInvestment(value: number) {
    this.actualOutcome.totalInvestment = value;
  }

  // actual outcome production getters and setters
  public get actualOutcomeProductionPerYearUnit(): Unit {
    return this.actualOutcomeProductionPerYear?.unit;
  }
  public set actualOutcomeProductionPerYearUnit(value: Unit) {
    this.actualOutcomeProductionPerYear.unit = value;
  }
  public get actualOutcomeProductionPerYearUnitInText(): string {
    return this.actualOutcomeProductionPerYear?.unitInText;
  }
  public set actualOutcomeProductionPerYearUnitInText(value: string) {
    this.actualOutcomeProductionPerYear.unitInText = value;
  }
  public get actualOutcomeProductionPerYearResource(): string {
    return this.actualOutcomeProductionPerYear?.resource;
  }
  public set actualOutcomeProductionPerYearResource(value: string) {
    this.actualOutcomeProductionPerYear.resource = value;
  }
  public get actualOutcomeProductionPerYearValue(): number {
    return this.actualOutcomeProductionPerYear?.value;
  }
  public set actualOutcomeProductionPerYearValue(value: number) {
    this.actualOutcomeProductionPerYear.value = value;
  }

  // actual outcome emission getters and setters
  public get actualOutcomeEmissionPerYearUnit(): Unit {
    return this.actualOutcomeEmissionPerYear?.unit;
  }
  public set actualOutcomeEmissionPerYearUnit(value: Unit) {
    this.actualOutcomeEmissionPerYear.unit = value;
  }
  public get actualOutcomeEmissionPerYearResource(): string {
    return this.actualOutcomeEmissionPerYear?.resource;
  }
  public set actualOutcomeEmissionPerYearResource(value: string) {
    this.actualOutcomeEmissionPerYear.resource = value;
  }
  public get actualOutcomeEmissionPerYearValue(): number {
    return this.actualOutcomeEmissionPerYear?.value;
  }
  public set actualOutcomeEmissionPerYearValue(value: number) {
    this.actualOutcomeEmissionPerYear.value = value;
  }

  // actual outcome reduction getters and setters
  public get actualOutcomeReductionPerYearUnit(): Unit {
    return this.actualOutcomeReductionPerYear.unit;
  }
  public set actualOutcomeReductionPerYearUnit(value: Unit) {
    this.actualOutcomeReductionPerYear.unit = value;
  }
  public get actualOutcomeReductionPerYearResource(): string {
    return this.actualOutcomeReductionPerYear.resource;
  }
  public set actualOutcomeReductionPerYearResource(value: string) {
    this.actualOutcomeReductionPerYear.resource = value;
  }
  public get actualOutcomeReductionPerYearValue(): number {
    return this.actualOutcomeReductionPerYear.value;
  }
  public set actualOutcomeReductionPerYearValue(value: number) {
    this.actualOutcomeReductionPerYear.value = value;
  }

  // project plan getters and setters
  public get projectPlanStartDate(): string {
    return this.projectPlan?.startDate
  }
  public set projectPlanStartDate(value: string) {
    this.projectPlan.startDate = value;
  }
  public get projectPlanEstimatedCompletion(): string {
    return this.projectPlan?.estimatedCompletion
  }
  public set projectPlanEstimatedCompletion(value: string) {
    this.projectPlan.estimatedCompletion = value;
  }
  public get projectPlanActualCompletion(): string {
    return this.projectPlan?.actualCompletion
  }
  public set projectPlanActualCompletion(value: string) {
    this.projectPlan.actualCompletion = value;
  }

  // admin getters and setters
  public get adminComment(): string {
    return this.admin?.comment
  }
  public set adminComment(value: string) {
    !this.admin ? this.admin = {
      comment: value,
      responsible: ''
    } : this.admin.comment = value;

  }
  public get adminResponsible(): string {
    return this.admin?.responsible
  }
  public set adminResponsible(value: string) {
    !this.admin ? this.admin = {
      responsible: value,
      comment: ''
    } : this.admin.responsible = value;
  }

  // financing getters and setters
  public get financingFinancingFee(): number {
    return this.financing?.financingFee
  }
  public set financingFinancingFee(value: number) {
    this.financing.financingFee = value;
  }
  public get financingDurationYears(): number {
    return this.financing?.durationYears
  }
  public set financingDurationYears(value: number) {
    this.financing.durationYears = value;
  }
  public get financingFinancedPercentage(): number {
    return this.financing?.financedPercentage
  }
  public set financingFinancedPercentage(value: number) {
    this.financing.financedPercentage = value;
  }
  public get financingInternalRateOfReturn(): number {
    return this.financing?.internalRateOfReturn
  }
  public set financingInternalRateOfReturn(value: number) {
    this.financing.internalRateOfReturn = value;
  }
  public get financingResidualValue(): number {
    return this.financing?.residualValue
  }
  public set financingResidualValue(value: number) {
    this.financing.residualValue = value;
  }
  public get financingInvestor(): string {
    return this.financing?.investor
  }
  public set financingInvestor(value: string) {
    this.financing.investor = value;
  }


  // address getters and setters
  public get addressStreet(): string {
    return this.address?.street
  }
  public set addressStreet(value: string) {
    this.address.street = value;
  }
  public get addressPostalCode(): string {
    return this.address?.postalCode
  }
  public set addressPostalCode(value: string) {
    this.address.postalCode = value;
  }
  public get addressCity(): string {
    return this.address?.city
  }
  public set addressCity(value: string) {
    this.address.city = value;
  }
  public get addressCountry(): string {
    return this.address?.country
  }
  public set addressCountry(value: string) {
    this.address.country = value;
  }

  // contanct getters and setters
  public get contactName(): string {
    return this.contact?.name
  }
  public set contactName(value: string) {
    this.contact.name = value;
  }
  public get contactEmail(): string {
    return this.contact?.email
  }
  public set contactEmail(value: string) {
    this.contact.email = value;
  }
}