import * as _global from '@/global';
import { ProjectService } from '@/services/project.service';
import { AlertType } from '@/shared/interface/alert.interface';

import { AlertActions } from '../alerts/actions';
import { GroupActions } from '../group/actions';

import type { IProject } from '@/shared/interface/project.interface';
import type { ActionTree } from 'vuex';
import type { RootState } from '../types';
import type { ProjectState } from './types';

export enum ProjectActions {
  updateProject = 'projects/updateProject',
  updateProjects = 'projects/updateProjects',
  addProject = 'projects/addProject',
  deleteProject = 'projects/deleteProject',
  clearProjectCrudState = 'projects/clearProjectCrudState',
  fetchProjects = 'projects/ensureLoadedProjects'
}


export const actions: ActionTree<ProjectState, RootState> = {
  async ensureLoadedProjects({ rootState, commit, dispatch }): Promise<void> {
    const state: any = rootState;
    const impersonationId = state.hosts.impersonationId ?? undefined;
    try {
      const projects: IProject[] = await new ProjectService().getAllProjects(impersonationId);
      commit("setProjects", projects);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit("setProjects", []);
    }
    commit("hasLoadedProjects", true);
  },
  async deleteProject({ dispatch, commit }, project: IProject): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    try {
      await new ProjectService().deleteProject(project.id);
      commit('removeProjectFromStore', project.id)
      commit('setCrudState', { loading: false, message: '', success: true });
      dispatch(AlertActions.QueueTost, { text: "PROJECT.CRUD.DELETE_SUCCESS", type: AlertType.Success }, { root: true })
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    } finally {
      commit('clearCrudState', _global.default.DEFAULT_TIME_OUT);
    }
  },
  async addProject({ dispatch, commit }, project: IProject): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    try {
      const newProject = await new ProjectService().addProject(project);
      commit('addProjectToStore', newProject);
      commit('setCrudState', { loading: false, message: '', success: true });
      dispatch(AlertActions.QueueTost, { text: "PROJECT.CRUD.CREATE_SUCCESS", type: AlertType.Success }, { root: true })
      dispatch(GroupActions.getGroups, {}, { root: true });
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    } finally {
      commit('clearCrudState', _global.default.DEFAULT_TIME_OUT);
    }

  },
  async updateProject({ dispatch, commit }, project: IProject): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    try {
      const udpatedProject = await new ProjectService().updateProject(project);
      commit("updateProjectsInStore", [udpatedProject] ?? []);
      commit('setCrudState', { loading: false, message: '', success: true });
      dispatch(AlertActions.QueueTost, { text: "PROJECT.CRUD.UPDATE_SUCCESS", type: AlertType.Success }, { root: true })
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    } finally {
      commit('clearCrudState', _global.default.DEFAULT_TIME_OUT);
    }
  },
  async updateProjects({ dispatch, commit }, projects: IProject[]): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    const projectService = new ProjectService();
    try {
      const result = await projectService.updateProjects(projects);
      commit("updateProjectsInStore", result ?? []);
      commit('setCrudState', { loading: false, message: '', success: true });
      dispatch(AlertActions.QueueTost, { text: "PROJECT.CRUD.UPDATE_SUCCESS", type: AlertType.Success }, { root: true })
    } catch (e: any) {
      dispatch(AlertActions.QueueAlert, {
        text: e.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    } finally {
      commit('clearCrudState', _global.default.DEFAULT_TIME_OUT);
    }
  },
  clearProjectCrudState({ commit }): void {
    commit('clearCrudState');
  }
}