import type { EmitterType } from '../enum/emitter-types.enum';
import type { Unit } from '../enum/unit.enum';
import type { IEmitter, IEmitterState } from '../interface/emitter.interface';
import { EmitterState } from './emitter-state.model';
import { v4 as uuidv4 } from "uuid";
import type { EmissionResourceType, ResourceType } from './resource-type.model';

export class Emitter implements IEmitter {
  id: string;
  name: string;
  type: EmitterType;
  unit: Unit;
  tagIds: string[];
  screeningState: IEmitterState;
  currentState: IEmitterState;
  identifiedReduction: IEmitterState;

  constructor(emitter?: IEmitter) {
    if (emitter) {
      this.id = emitter.id;
      this.name = emitter.name;
      this.type = emitter.type;
      this.unit = emitter.unit;
      this.tagIds = emitter.tagIds ?? [];
      this.screeningState = emitter.screeningState ?? new EmitterState();
      this.currentState = emitter.currentState ?? new EmitterState();
      this.identifiedReduction = emitter.identifiedReduction ?? new EmitterState();
    } else {
      this.screeningState = new EmitterState();
      this.currentState = new EmitterState();
      this.identifiedReduction = new EmitterState();
      this.tagIds = [];
      this.id = uuidv4();
    }
  }


  // emitter state getters
  public get screeningEmissionPerYear(): EmissionResourceType {
    return this.screeningState?.emissionPerYear;
  }

  public set screeningEmissionPerYear(value: EmissionResourceType) {
    this.screeningState.emissionPerYear = value;
  }

  public get screeningStateConsumption(): ResourceType {
    return this.screeningState.consumptionPerYear;
  }

  public set screeningStateConsumption(value: ResourceType) {
    this.screeningState.consumptionPerYear = value;
  }


  // Current
  public get currentEmissionPerYear(): EmissionResourceType {
    return this.currentState?.emissionPerYear;
  }

  public set currentEmissionPerYear(value: EmissionResourceType) {
    this.currentState.emissionPerYear = value;
  }

  public get currentStateConsumption(): ResourceType {
    return this.currentState.consumptionPerYear;
  }

  public set currentStateConsumption(value: ResourceType) {
    this.currentState.consumptionPerYear = value;
  }


  // Identified
  public get identifiedEmissionPerYear(): EmissionResourceType {
    return this.identifiedReduction?.emissionPerYear;
  }

  public set identifiedEmissionPerYear(value: EmissionResourceType) {
    this.identifiedReduction.emissionPerYear = value;
  }
  public get identifiedStateConsumption(): ResourceType {
    return this.identifiedReduction.consumptionPerYear;
  }

  public set identifiedStateConsumption(value: ResourceType) {
    this.identifiedReduction.consumptionPerYear = value;
  }

  // Identified getters and setters
  public get identifiedStateEmissionPerYear(): number {
    return this.identifiedEmissionPerYear?.value;
  }

  public set identifiedStateEmissionPerYear(value: number) {
    this.identifiedEmissionPerYear.value = value;
  }

  public get identifiedStateEmissionPerYearUnit(): Unit {
    return this.identifiedEmissionPerYear?.unit;
  }

  public set identifiedStateEmissionPerYearUnit(value: Unit) {
    this.identifiedEmissionPerYear.unit = value;
  }

  public get identifiedStateEmissionPerYearResource(): Unit {
    return this.identifiedEmissionPerYear?.unit;
  }

  public set identifiedStateEmissionPerYearResource(value: Unit) {
    this.identifiedEmissionPerYear.unit = value;
  }

  public get identifiedStateConsumptionPerYear(): number {
    return this.identifiedStateConsumption?.value;
  }

  public set identifiedStateConsumptionPerYear(value: number) {
    this.identifiedStateConsumption.value = value;
  }

  public get identifiedStateConsumptionPerYearUnit(): Unit {
    return this.identifiedStateConsumption?.unit;
  }

  public set identifiedStateConsumptionPerYearUnit(value: Unit) {
    this.identifiedStateConsumption.unit = value;
  }

  public get identifiedStateConsumptionPerYearResource(): string {
    return this.identifiedStateConsumption?.resource;
  }

  public set identifiedStateConsumptionPerYearResource(value: string) {
    this.currentStateConsumption.resource = value;
  }

  public get identifiedStateCostPerYear(): number {
    return this.identifiedReduction?.costPerYear
  }

  public set identifiedStateCostPerYear(value: number) {
    this.identifiedReduction.costPerYear = value;
  }

  // Current state getters and setters

  public get currentStateEmissionPerYear(): number {
    return this.currentEmissionPerYear?.value;
  }

  public set currentStateEmissionPerYear(value: number) {
    this.currentEmissionPerYear.value = value;
  }

  public get currentStateEmissionPerYearUnit(): Unit {
    return this.currentEmissionPerYear?.unit;
  }

  public set currentStateEmissionPerYearUnit(value: Unit) {
    this.currentEmissionPerYear.unit = value;
  }

  public get currentStateEmissionPerYearResource(): Unit {
    return this.currentEmissionPerYear?.unit;
  }

  public set currentStateEmissionPerYearResource(value: Unit) {
    this.currentEmissionPerYear.unit = value;
  }

  public get currentStateConsumptionPerYear(): number {
    return this.currentStateConsumption?.value;
  }

  public set currentStateConsumptionPerYear(value: number) {
    this.currentStateConsumption.value = value;
  }

  public get currentStateConsumptionPerYearUnit(): Unit {
    return this.currentStateConsumption?.unit;
  }

  public set currentStateConsumptionPerYearUnit(value: Unit) {
    this.currentStateConsumption.unit = value;
  }

  public get currentStateConsumptionPerYearResource(): string {
    return this.currentStateConsumption?.resource;
  }

  public set currentStateConsumptionPerYearResource(value: string) {
    this.currentStateConsumption.resource = value;
  }

  public get currentStateCostPerYear(): number {
    return this.currentState?.costPerYear
  }

  public set currentStateCostPerYear(value: number) {
    this.currentState.costPerYear = value;
  }

  // Screening state getters and setters

  public get screeningStateEmissionPerYear(): number {
    return this.screeningEmissionPerYear?.value;
  }

  public set screeningStateEmissionPerYear(value: number) {
    this.screeningEmissionPerYear.value = value;
  }

  public get screeningStateEmissionPerYearUnit(): Unit {
    return this.screeningEmissionPerYear?.unit;
  }

  public set screeningStateEmissionPerYearUnit(value: Unit) {
    this.screeningEmissionPerYear.unit = value;
  }

  public get screeningStateEmissionPerYearResource(): Unit {
    return this.screeningEmissionPerYear?.unit;
  }

  public set screeningStateEmissionPerYearResource(value: Unit) {
    this.screeningEmissionPerYear.unit = value;
  }

  public get screeningStateConsumptionPerYear(): number {
    return this.screeningStateConsumption?.value;
  }

  public set screeningStateConsumptionPerYear(value: number) {
    this.screeningStateConsumption.value = value;
  }

  public get screeningStateConsumptionPerYearUnit(): Unit {
    return this.screeningStateConsumption?.unit;
  }

  public set screeningStateConsumptionPerYearUnit(value: Unit) {
    this.screeningStateConsumption.unit = value;
  }

  public get screeningStateConsumptionPerYearResource(): string {
    return this.screeningStateConsumption?.resource;
  }

  public set screeningStateConsumptionPerYearResource(value: string) {
    this.screeningStateConsumption.resource = value;
  }

  public get screeningStateCostPerYear(): number {
    return this.screeningState?.costPerYear
  }

  public set screeningStateCostPerYear(value: number) {
    this.screeningState.costPerYear = value;
  }
}