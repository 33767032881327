import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { Group } from '@/shared/interface/group.interface';
import type { MutationTree } from 'vuex';
import type { GroupState } from './types';


export const mutations: MutationTree<GroupState> = {
  setGroups(state, groups: Group[]) {
    state.groups = groups ?? [];
  },
  hasLoadedGroups(state, payload: boolean) {
    state.groupsHasLoaded = payload
  },
  setCrudState(state, crud: ICrudState) {
    state.crudState = crud;
  },
  clearCrudState(state, payload?: number) {
    if (payload && payload > 0) {
      setTimeout(() => {
        state.crudState = null;
      }, payload);
    } else {
      state.crudState = null;
    }
  },
  clearGroupFromState(state, group: Group) {
    state.groups = state.groups.filter((stateState) => stateState.id !== group.id);
  },
  addGroupToState(state, group: Group) {
    state.groups = [...state.groups ?? [], group];
  },
  updateGroupsInState(state, groups: Group[]) {
    const newState = state.groups.map((group) => {
      const updatedGroup = groups.find((updatedGroup) => updatedGroup.id === group.id);
      return updatedGroup ? updatedGroup : group;
    });
    state.groups = [...newState]
  }
}