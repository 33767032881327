import type { IAddress } from '../interface/address.interface';
export class Address implements IAddress {
  public street: string;
  public postalCode: string;
  public city: string;
  public country: string;
  constructor(address?: IAddress) {
    this.street = address?.street;
    this.postalCode = address?.postalCode;
    this.city = address?.city;
    this.country = address?.country;
  }
}