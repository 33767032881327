import './styles/main.scss';
import "@/plugins/bootstrap-vue";
import 'babel-polyfill';
import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import sharedComponents from '@/shared/components/shared-component.plugin';
import { accessPlugin } from './plugins/access.plugin';
import i18n from './i18n'
Vue.use(accessPlugin);
Vue.use(sharedComponents);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");