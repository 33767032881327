import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { Group } from '@/shared/interface/group.interface';
import type { GetterTree } from 'vuex';
import type { RootState } from '../types';
import type { GroupState } from './types';

export enum GroupGetters {
  getGroups = 'groups/getGroups',
  isSavingGroup = 'group/isSavingGroups',
  hasLoadedGroups = 'groups/hasLoadedGroups',
  groupCrudState = 'groups/getGroupCrudState',
}

export const getters: GetterTree<GroupState, RootState> = {
  getGroups(state): Group[] {
    return state.groups;
  },
  getIsSavingGroups(state): boolean {
    return state.crudState?.loading ?? false;
  },
  hasLoadedGroups(state): boolean {
    return state.groupsHasLoaded;
  },
  getGroupCrudState(state): ICrudState | null {
    return state.crudState
  },
}