import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

import type { Module } from 'vuex';
import type { RootState } from '@/store/types';
import type { WorkflowState } from './types';

export const state: WorkflowState = {
  workflows: [],
  workflowsHasLoaded: false,
  crudState: null,
};

const namespaced = true;

export const workflowState: Module<WorkflowState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
