import i18n from '@/i18n';

import { EmitterType } from './emitter-types.enum';
import { HostStatusEnum } from './host-status.enum';
import { ProjectStatusEnum } from './project-status.enum';
import { ProjectTypesEnum } from './project-types.enum';
import { Unit } from './unit.enum';
import { UserTypeEnum } from './user-type.enum';

import type { IEnumTranslation } from '../interface/enum-translation.interface';
import { WorkflowType } from './workflow-type.enum';
const translatedUnits = (): IEnumTranslation[] => {
  return Object.keys(Unit).map((unit) => {
    return {
      text: i18n.t("UNIT." + unit.toString()).toString(),
      value: unit,
    };
  });
}
const translatedEmitterTypes = (): IEnumTranslation[] => {
  return Object.keys(EmitterType).map((emitter) => {
    return {
      text: i18n.t("EMITTER_TYPE." + emitter.toString()).toString(),
      value: emitter,
    };
  });
}

const translatedHostStatus = (): IEnumTranslation[] => {
  return Object.keys(HostStatusEnum).map((status) => {
    return {
      text: i18n.t("HOST_STATUS." + status.toString()).toString(),
      value: status,
    };
  });
}

const translatedProjectStatus = (): IEnumTranslation[] => {
  return Object.keys(ProjectStatusEnum).map((status) => {
    return {
      text: i18n.t("PROJECT_STATUS." + status.toString()).toString(),
      value: status,
    };
  });
}

const translatedProjectTypes = (): IEnumTranslation[] => {
  return Object.keys(ProjectTypesEnum).map((status) => {
    return {
      text: i18n.t("PROJECT_TYPE." + status.toString()).toString(),
      value: status,
    };
  });
}

const translatedUserTypes = (): IEnumTranslation[] => {
  return Object.keys(UserTypeEnum).map((x) => {
    return {
      text: i18n.t("USER_TYPE." + x.toString()).toString(),
      value: x,
    };
  });
}

const translatedWorkflowTypes = (): IEnumTranslation[] => {
  return Object.keys(WorkflowType).map((x) => {
    return {
      text: i18n.t("WORKFLOW_TYPE." + x.toString()).toString(),
      value: x,
    };
  });
}


export {
  translatedUnits,
  translatedEmitterTypes,
  translatedHostStatus,
  translatedProjectStatus,
  translatedProjectTypes,
  translatedUserTypes,
  translatedWorkflowTypes
};
