import { formatPercentage } from './formatter.utils';

const calculatePercentage = (
  numerator: number,
  denominator: number,
  invert = false
): string => {
  if ((numerator || numerator === 0)) {
    return invert
      ? formatPercentage((1 - numerator / denominator ?? 1) * 100)
      : formatPercentage((numerator / denominator ?? 1) * 100);
  } else {
    return null;
  }
}

const subtract = (x: number, y: number): number => {
  return Number.isFinite(x) && Number.isFinite(y) ? x - y : null;
}

export {
  calculatePercentage,
  subtract
}