import store from '@/store';
import { GroupGetters } from '@/store/group/getters';
import { UserGetters } from '@/store/user/getters';
import Vue from 'vue';

import { UserRoleEnum } from '../shared/enum/user-roles.enum';

import type { AuthenticationResult } from '@azure/msal-browser';
import type { UserRow } from '@/shared/interface/user-row.interface';
import type { Group } from '@/shared/interface/group.interface';
export const hasAccess = (requiredRole?: UserRoleEnum, token?: AuthenticationResult): boolean => {
  const auth: AuthenticationResult = store.getters[UserGetters.getAuth];
  const idToken: any = token ? (token as any)?.idTokenClaims : auth?.account?.idTokenClaims;
  const userRoles: UserRoleEnum[] = idToken?.roles;
  return Vue.prototype.$hasAccess(requiredRole, userRoles);
}

export const canEdit = (): boolean => {
  const auth: AuthenticationResult = store.getters[UserGetters.getAuth];
  const idToken: any = auth?.account?.idTokenClaims;
  const userRoles: UserRoleEnum[] = idToken?.roles;

  if (Vue.prototype.$hasAccess(UserRoleEnum.Admin, userRoles)) {
    return true;
  }

  if (Vue.prototype.$hasAccess(UserRoleEnum.User, userRoles)) {
    const groups: Group[] = store.getters[GroupGetters.getGroups];
    const user: UserRow = store.getters[UserGetters.getMyUser];

    if (groups && user) {
      return groups.find((g) => g.id === user.selectedGroup)?.editors.includes(user.id);
    }
  }

  return false;
}
