import { render, staticRenderFns } from "./GlobalToast.vue?vue&type=template&id=053661dc&scoped=true&"
import script from "./GlobalToast.vue?vue&type=script&lang=ts&"
export * from "./GlobalToast.vue?vue&type=script&lang=ts&"
import style0 from "./GlobalToast.vue?vue&type=style&index=0&id=053661dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_a7yqy5dhmduz2mxkyxhvyeinou/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "053661dc",
  null
  
)

export default component.exports