export enum AlertType {
  Danger = 'danger',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

export interface IAlert extends IQueueAlert {
  id: number;
}

export interface IQueueAlert {
  text: string;
  type: AlertType;
}
