import type { Module } from 'vuex';
import type { RootState } from '@/store/types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import type { HostState } from './types';

export const state: HostState = {
  hosts: [],
  emitterTypes: [],
  hostsHasLoaded: false,
  crudState: null,
  emittersHasLoaded: false
};

const namespaced = true;

export const hostState: Module<HostState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
