import * as _global from '@/global';
import { UserService } from '@/services/user.service';
import { AlertType } from '@/shared/interface/alert.interface';

import { AlertActions } from '../alerts/actions';
import { HostActions } from '../host/actions';
import { ProjectActions } from '../project/actions';

import type { UserRow } from '@/shared/interface/user-row.interface';
import type { ActionTree } from 'vuex';
import type { RootState } from '../types';
import type { UserState } from './types';
import type { IUserSettings } from '@/shared/interface/user-settings.interface';

export enum UserActions {
  addUser = "user/addUser",
  updateUser = "user/updateUser",
  deleteUser = "user/deleteUser",
  clearCrudState = "user/clearCrudState",
  fetchUsers = "user/fetchUsers",
  getMyUser = "user/getMyUser",
  hideAdminFeatures = "user/hideAdminFeatures",
  setUserSettings = "user/setUserSettings",
  setMsalAuth = "user/setMsalAuth",
  setIsLoggingIn = "user/setIsLoggingIn"
}

export const actions: ActionTree<UserState, RootState> = {
  async fetchUsers({ commit, dispatch }): Promise<void> {
    commit("hasLoadedUsers", false);
    try {
      const users: UserRow[] = await new UserService().getAllUsers();
      commit("setUsers", users);
    } catch (error: any) {
      commit("setUsers", []);
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
    }
    commit("hasLoadedUsers", true);
  },
  async deleteUser({ dispatch, commit }, user: UserRow): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    try {
      const deletedUser = await new UserService().deleteUser(user);
      commit('removeUserFromState', deletedUser);
      commit('setCrudState', { loading: false, message: '', success: true });
      dispatch(AlertActions.QueueTost, { text: "USER.CRUD.DELETE_SUCCESS", type: AlertType.Success }, { root: true })
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    } finally {
      commit('clearCrudState', _global.default.DEFAULT_TIME_OUT);
    }
  },
  async addUser({ dispatch, commit }, user: UserRow): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    try {
      const newUSer = await new UserService().addUser(user);
      commit('addUserToStore', newUSer);
      commit('setCrudState', { loading: false, message: '', success: true });
      dispatch(AlertActions.QueueTost, { text: "USER.CRUD.CREATE_SUCCESS", type: AlertType.Success }, { root: true })
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    } finally {
      commit('clearCrudState', _global.default.DEFAULT_TIME_OUT);
    }
  },
  async updateUser({ commit, dispatch }, payload: { user: UserRow, fetchData: boolean }): Promise<void> {
    commit('setCrudState', { loading: true, message: '', success: null });
    try {
      const userResponse = await new UserService().updateUser(payload.user);
      commit('updateUserInStore', [userResponse])
      commit('setCrudState', { loading: false, message: '', success: true });
      commit('setUser', userResponse);
      dispatch(AlertActions.QueueTost, { text: "USER.CRUD.UPDATE_SUCCESS", type: AlertType.Success }, { root: true })

      if (payload.fetchData) {
        dispatch(HostActions.fetchHosts, {}, { root: true });
        dispatch(ProjectActions.fetchProjects, {}, { root: true });
      }
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true });
      commit('setCrudState', { loading: false, message: '', success: false });
    } finally {
      commit('clearCrudState', _global.default.DEFAULT_TIME_OUT);
    }
  },
  setMsalAuth({ commit }, auth): void {
    commit('setMsalAuth', auth)
  },
  setIsLoggingIn({ commit }, payload: boolean) {
    commit('setLoggingIn', payload)
  },

  async getMyUser({ commit, dispatch }): Promise<void> {
    try {
      const user: UserRow = await new UserService().getMyUser();
      commit("setUser", user);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
    }
  },

  hideAdminFeatures({ commit }): void {
    commit('hideAdminFeatures')
  },
  setUserSettings({ commit }, payload: IUserSettings) {
    if (payload) {
      commit('setUserSettings', payload)
    } else {
      commit('setUserSettings', null)
    }
  }
}