export enum ProjectTypesEnum {
  SOLAR = 'SOLAR',
  LED = 'LED',
  ENERGYEFFICIENCY = 'ENERGYEFFICIENCY',
  STORAGE = 'STORAGE',
  BIOENERGY = 'BIOENERGY',
  WIND = 'WIND',
  OTHERELECTRICITY = 'OTHERELECTRICITY',
  TRANSPORT = 'TRANSPORT',
  GEOTHERMAL = 'GEOTHERMAL',
  HEATING = 'HEATING',
  OTHERTYPE = 'OTHERTYPE',
}