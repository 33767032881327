import { EmitterType } from '@/shared/enum/emitter-types.enum';
import { ProjectTypesEnum } from '../shared/enum/project-types.enum';
import { Unit } from '../shared/enum/unit.enum';

const getUnitByProjectType = (projectType: ProjectTypesEnum): Unit => {
  const projectTypeUnitMap: Map<ProjectTypesEnum, Unit> = new Map(
    [
      [ProjectTypesEnum.SOLAR, Unit.Kwh],
      [ProjectTypesEnum.LED, Unit.Kwh],
      [ProjectTypesEnum.ENERGYEFFICIENCY, Unit.Kwh],
      [ProjectTypesEnum.STORAGE, Unit.Kwh],
      [ProjectTypesEnum.BIOENERGY, Unit.None],
      [ProjectTypesEnum.WIND, Unit.Kwh],
      [ProjectTypesEnum.OTHERELECTRICITY, Unit.Kwh],
      [ProjectTypesEnum.TRANSPORT, Unit.Liter],
      [ProjectTypesEnum.GEOTHERMAL, Unit.Kwh],
      [ProjectTypesEnum.HEATING, Unit.None],
      [ProjectTypesEnum.OTHERTYPE, Unit.None],
    ]
  );
  return projectTypeUnitMap.get(projectType);
}


const getUnitByEmitterType = (emitter: EmitterType): Unit => {
  const emitterUnitMap: Map<EmitterType, Unit> = new Map(
    [
      [EmitterType.Diesel, Unit.Liter],
      [EmitterType.NaturalGas, Unit.Tonne],
      [EmitterType.Coal, Unit.Tonne],
      [EmitterType.Electricity, Unit.Kwh],
      [EmitterType.Oil, Unit.Liter],
      [EmitterType.Petrol, Unit.Liter],
      [EmitterType.Co2, Unit.Tonne]
    ]
  )
  return emitterUnitMap.get(emitter);
}

export {
  getUnitByProjectType,
  getUnitByEmitterType
}