import { UserService } from '@/services/user.service';
import store from '@/store';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';

import { loginRequest, msalInstance, silentRequest } from '../../msal';

export const validateUserTokenAndStartSignIn = async (): Promise<boolean> => {
  const token = await acquireToken(true);
  if (token) {
    msalInstance.setActiveAccount(token.account);
    store.dispatch('user/setMsalAuth', token)
    return true;
  } else {
    return false;
  }
}

export const UserIsLoggedIn = async (): Promise<boolean> => {
  try {
    const token = await acquireToken();
    return token !== null;
  } catch (e) {
    return false;
  }
}

export const acquireToken = async (forceRefresh?: boolean): Promise<AuthenticationResult | null> => {
  try {
    const accessTokenRequest = !forceRefresh ? {
      scopes: ["user.read"],
      account: msalInstance.getActiveAccount() ?? undefined
    } : {
      scopes: [] as any[],
      forceRefresh: true
    }
    return await msalInstance.acquireTokenSilent(accessTokenRequest);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return await acquireTokenThroughPopup();
    } else {
      return null;
    }
  }
}

export const acquireTokenThroughPopup = async (): Promise<AuthenticationResult | null> => {
  try {
    return await msalInstance.acquireTokenPopup(loginRequest);
  } catch (error) {
    return null;
  }
}

export const signIn = async (): Promise<boolean> => {
  store.dispatch('user/setIsLoggingIn', true);
  const result = await signInSilently();
  if (result) {
    msalInstance.setActiveAccount(result.account);
    store.dispatch('user/setMsalAuth', result)
    store.dispatch('user/setIsLoggingIn', false);
    store.dispatch('user/setIsLoggingIn', true);
    return true;
  } else {
    msalInstance.setActiveAccount(null);
    store.dispatch('user/setMsalAuth', null)
    store.dispatch('user/setIsLoggingIn', false);
    return false;
  }
}

export const signOut = async (): Promise<null> => {
  try {
    await msalInstance.logoutPopup({ mainWindowRedirectUri: '/' });
    msalInstance.setActiveAccount(null);
    store.dispatch('user/setMsalAuth', null)
    new UserService().saveSettingsToStorage(null);
    return null;
  } catch {
    return null;
  }
}

const signInWithPopup = async (): Promise<AuthenticationResult | null> => {
  try {
    return await msalInstance.loginPopup(loginRequest);
  } catch (e) {
    return null
  }
}

const signInSilently = async (): Promise<AuthenticationResult | null> => {
  try {
    const firstAvailableAccount = msalInstance.getAllAccounts()[0];
    silentRequest.loginHint = firstAvailableAccount?.username;
    return await msalInstance.ssoSilent(silentRequest);
  } catch (e) {
    return await signInWithPopup()
  }
}
