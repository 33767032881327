import { WorkflowService } from '@/services/workflow.service';
import { AlertType } from '@/shared/interface/alert.interface';

import { AlertActions } from '../alerts/actions';

import type { ActionTree } from 'vuex';
import type { RootState } from '../types';
import type { Workflow } from '@/shared/interface/workflow.interface';
import type { WorkflowState } from './types';
import type { IWorkflowToStart } from '@/shared/interface/workflow-to-start.interface';

export enum WorkflowActions {
  getWorkflows = 'workflows/getWorkflows',
  updateWorkflow = "workflows/updateWorkflow",
  deleteWorkflow = 'workflows/deleteWorkflow',
  clearWorkflowCrudState = 'workflows/clearWorkflowCrudState',
  startWorkflow = 'workflows/startWorkflow'
}

export const actions: ActionTree<WorkflowState, RootState> = {
  async getWorkflows({ dispatch, commit }): Promise<void> {
    try {
      const workflows: Workflow[] = await new WorkflowService().getWorkflows();
      commit("setWorkflows", workflows);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true });
      commit("setWorkflows", []);
    }
    commit('hasLoadedWorkflows', true);
  },

  async deleteWorkflow({ dispatch, commit }, workflow: Workflow): Promise<void> {
    commit('setCrudState', { loading: true, success: null }); try {
      await new WorkflowService().deleteWorkflow(workflow.id);
      dispatch(AlertActions.QueueTost, { text: "WORKFLOW.CRUD.DELETE_SUCCESS", type: AlertType.Success }, { root: true })
      commit('setCrudState', { loading: false, success: true });
      commit('clearWorkflowFromState', workflow);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    }
  },

  async updateWorkflow({ dispatch, commit }, workflow: Workflow): Promise<void> {
    commit('setCrudState', { loading: true, success: null });
    try {
      const updatedWorkflow = await new WorkflowService().updateWorkflow(workflow);
      dispatch(AlertActions.QueueTost, { text: "WORKFLOW.CRUD.UPDATE_SUCCESS", type: AlertType.Success }, { root: true })
      commit('setCrudState', { loading: false, success: true });
      commit('updateWorkflowsInState', [updatedWorkflow]);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, success: false });
    }
  },
  clearWorkflowCrudState({ commit }): void {
    commit('clearCrudState');
  },
  async startWorkflow({ dispatch, commit }, workflow: IWorkflowToStart): Promise<void> {
    commit('setCrudState', { loading: true, success: null });
    try {
      const newWorkflow: Workflow = await new WorkflowService().startWorkFlow(workflow);
      commit("addWorkflowToState", newWorkflow);
      commit('setCrudState', { loading: false, success: true });
      dispatch(AlertActions.QueueTost, { text: "WORKFLOW.CRUD.CREATE_SUCCESS", type: AlertType.Success }, { root: true })

    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true });
      commit('setCrudState', { loading: false, success: false });
    }
  },
}