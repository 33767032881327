import store from '@/store';
import { acquireToken } from '@/utils/login.utils';
import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

import { UserRoleEnum } from './shared/enum/user-roles.enum';
import { AlertActions } from './store/alerts/actions';
import { hasAccess } from './utils/access.utils';

import type { AuthenticationResult } from '@azure/msal-browser';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/rison-portal',
    name: 'start',
    redirect: {
      name: 'ProjectList'
    },
    component: () => import(/* webpackChunkName: "rison-portal" */ '@/app/MainView.vue'),
    children: [
      {
        path: "hosts",
        name: 'hosts',
        meta: {},
        component: () => import(/* webpackChunkName: "hosts" */ "@/app/hosts/views/Hosts.vue"),
        children: [
          {
            path: "host-list",
            name: "HostList",
            meta: {
              activeOn: 'HostList'
            },
            component: () => import(/* webpackChunkName: "hosts" */ "@/app/hosts/views/HostList.vue"),
          },
          {
            path: "host/:id",
            name: "HostView",
            component: () => import(/* webpackChunkName: "hosts" */ "@/app/hosts/views/HostView.vue"),
            props: true,
            meta: {
              activeOn: 'HostList'
            },
          },
          {
            path: "host-table",
            name: "HostDataTable",
            meta: {
              requireAdmin: true,
              activeOn: 'HostList'
            },
            component: () => import(/* webpackChunkName: "hosts" */ "@/app/hosts/views/HostDataTable.vue"),
          },
        ]
      },
      {
        path: "projects",
        name: "projects",
        meta: {
          activeOn: 'ProjectList'
        },
        component: () => import(/* webpackChunkName: "projects" */ "@/app/projects/views/Projects.vue"),
        children: [
          {
            path: "project/:id",
            name: "ProjectView",
            component: () => import(/* webpackChunkName: "projects" */ "@/app/projects/views/ProjectView.vue"),
            props: true,
            meta: {
              activeOn: 'ProjectList'
            },
          },
          {
            path: "project-list",
            name: "ProjectList",
            component: () => import(/* webpackChunkName: "projects" */ "@/app/projects/views/ProjectList.vue"),
            meta: {
              activeOn: 'ProjectList'
            },
          },
          {
            path: "data-table",
            name: "ProjectsDataTable",
            meta: {
              requireAdmin: true,
              activeOn: 'ProjectList'
            },
            component: () => import(/* webpackChunkName: "projects" */ "@/app/projects/views/ProjectsTable.vue"),
          },
        ]
      },
      {
        path: "users",
        name: "Users",
        meta: {
          requireAdmin: true,
          activeOn: 'UserList'
        },
        redirect: {
          name: 'UserList',

        },
        component: () => import(/* webpackChunkName: "users" */ "@/app/users/views/Users.vue"),
        children: [
          {
            path: "user-list",
            name: "UserList",
            meta: {
              requireAdmin: true,
              activeOn: 'Admin'
            },
            component: () => import(/* webpackChunkName: "users" */ "@/app/users/views/UserList.vue"),
            props: true,
          },
        ]
      },
      {
        path: "tags",
        name: "Tags",
        meta: { requireAdmin: true },
        redirect: {
          name: 'TagList'
        },
        component: () => import(/* webpackChunkName: "users" */ "@/app/tags/views/Tags.vue"),
        children: [
          {
            path: "tag-list",
            name: "TagList",
            meta: {
              requireAdmin: true,
              activeOn: 'Admin'
            },
            component: () => import(/* webpackChunkName: "users" */ "@/app/tags/views/TagList.vue"),
            props: true,
          },
        ]
      },
      {
        path: "groups",
        name: "Groups",
        meta: { requireAdmin: true },
        redirect: {
          name: 'GroupList'
        },
        component: () => import(/* webpackChunkName: "groups" */ "@/app/groups/views/Groups.vue"),
        children: [
          {
            path: "group-list",
            name: "GroupList",
            meta: {
              requireAdmin: true,
              activeOn: 'Admin'
            },
            component: () => import(/* webpackChunkName: "groups" */ "@/app/groups/views/GroupList.vue"),
            props: true,
          },
        ]
      },
      {
        path: "workflows",
        name: "Workflows",
        redirect: {
          name: 'WorkflowList'
        },
        component: () => import(/* webpackChunkName: "workflows" */ "@/app/workflows/views/Workflows.vue"),
        children: [
          {
            path: "workflow-list",
            name: "WorkflowList",
            component: () => import(/* webpackChunkName: "workflows" */ "@/app/workflows/views/WorkflowList.vue"),
            props: true,
          },
        ]
      }
    ]
  },
  {
    path: "/login",
    name: "account-login",
    meta: { allowAnonymous: true },
    component: () => import(/* webpackChunkName: "account" */ "@/app/users/views/Login.vue"),
  },
  {
    path: '*',
    redirect: '/rison-portal'
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});


const getUrlByLoginStatus = (to: Route, isLoggedIn: boolean): string | null => {
  if (isLoggedIn === false && to.name !== 'account-login') {
    return 'account-login';
  } else if (to.name === 'account-login' && isLoggedIn === true) {
    return 'start';
  } else {
    return null;
  }
}

const navigationIsAllowedByAuth = (to: Route, token: AuthenticationResult): boolean => {
  return to?.meta?.requireAdmin ? hasAccess(UserRoleEnum.Admin, token) : true;
}

const handleRoutingRedirect = (to: Route, next: NavigationGuardNext, token: AuthenticationResult) => {
  const allowed = navigationIsAllowedByAuth(to, token);
  if (!allowed) {
    store.dispatch(AlertActions.QueueAlert, {
      text: 'GENERAL.ERROR.NOT_ALLOWED_TO_VIEW_PAGE',
      type: 'danger'
    })
    next('/rison-portal');
  } else (
    next()
  )
}

router.beforeEach(async (to, from, next) => {
  const token = await acquireToken();
  if (token) {
    handleRoutingRedirect(to, next, token)
  }
  const toUrl = getUrlByLoginStatus(to, !!token)
  toUrl ? next({ name: toUrl }) : next();
});

export { router };
