import http, { isCancel } from '@/axios-config';
import i18n from '@/i18n';
import { ErrorHandler } from '@/shared/errors/error-handler';
import { ErrorMap } from '@/shared/errors/error-map.model';
import type { IProject } from '@/shared/interface/project.interface';
import { calculatePercentage, subtract } from '@/utils/calculate.utils';
import { formatCurrency, formatNumber, formatPercentage, formatResourceAndUnit } from '@/utils/formatter.utils';
import { projectApiVersion } from './api.version';

export class ProjectService extends ErrorHandler {
  public async deleteProject(id: string): Promise<any> {
    try {
      const { data } = await http.delete(`${projectApiVersion}/Project/DeleteProject/${id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "PROJECT.CRUD.MISSING_RIGHTS_DELETE",
            statusCode: 403
          },
          {
            errorMessage: "PROJECT.CRUD.NOT_FOUND",
            statusCode: 404
          }
        ]))
      return null;
    }
  }

  public async addProject(project: IProject): Promise<IProject | null> {
    try {
      const { data } = await http.post(`${projectApiVersion}/Project/AddProject`, project)
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "PROJECT.CRUD.MISSING_RIGHTS_CREATE",
            statusCode: 403
          }
        ]))
      return null;
    }
  }


  public async updateProject(project: IProject): Promise<IProject | null> {
    try {
      const { data } = await http.put(`${projectApiVersion}/Project/UpdateProject`, project)
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "PROJECT.CRUD.NOT_FOUND",
            statusCode: 404
          },
          {
            errorMessage: "PROJECT.CRUD.MISSING_RIGHTS_UPDATE",
            statusCode: 403
          }
        ]))
      return null;
    }
  }


  public async getAllProjects(impersonationId?: string): Promise<IProject[]> {
    try {
      const { data } = await http.get(`${projectApiVersion}/Project/GetAllProjects`, {
        params: {
          'ImpersonationId': impersonationId
        }
      })
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "PROJECT.CRUD.MISSING_RIGHTS_READ",
            statusCode: 403
          }
        ]))
      return [];
    }
  }

  public async getProject(id: string, signal?: AbortSignal): Promise<IProject | null> {
    try {
      const { data } = await http.get(`${projectApiVersion}/Project/GetProject/${id}`,
        {
          signal: signal
        })
      return data;
    } catch (error: any) {
      if (!isCancel(error)) {
        this.handleError(error,
          new ErrorMap([
            {
              errorMessage: "PROJECT.CRUD.NOT_FOUND",
              statusCode: 404
            },
            {
              errorMessage: "PROJECT.CRUD.MISSING_RIGHTS_READ",
              statusCode: 403
            }
          ]))
      }
      return null;
    }
  }

  public async updateProjects(projects: IProject[]): Promise<IProject[] | null> {
    try {
      const { data } = await http.put(`${projectApiVersion}/Project/UpdateProjects`, projects);
      return data;
    } catch (error: any) {
      this.handleError(error,
        new ErrorMap([
          {
            errorMessage: "PROJECT.CRUD.MISSING_RIGHTS_UPDATE",
            statusCode: 403
          }
        ]));
    }
    return null;
  }


  public formatProjectForIdentifiedTable(project: IProject): any {
    return {
      reductionPerYearFormatted: this.calculateReductionPerYear(project),
      netSavingsFormatted: this.calculateNetSavings(project),
      irr: formatPercentage(project?.financing?.internalRateOfReturn),
      ratingFormatted: formatNumber(project?.rating),
      investmentFormatted: formatCurrency(
        formatNumber(project?.potentialOutcome?.totalInvestment),
        '€'
      ),
      grossSavingsFormatted: formatCurrency(formatNumber(project?.currentState?.costPerYear), '€'),
      opexFormatted: formatCurrency(formatNumber(project?.potentialOutcome?.opexPerYear), '€',
        i18n.t("GENERAL.PER_YEAR_SHORT").toString()
      ),
      translatedProjectType: i18n.te("PROJECT_TYPE." + project?.projectType)
        ? i18n.t("PROJECT_TYPE." + project?.projectType)
        : project?.projectType,
    }
  }

  public formatProjecForIdeaTable(proj: IProject): any {
    return {
      consumptionPerYearFormatted: formatResourceAndUnit(proj?.currentState?.consumptionPerYear),
      costPerYearFormatted: formatCurrency(formatNumber(proj?.currentState?.costPerYear), "€"),
      emissionPerYearFormatted: formatResourceAndUnit(proj?.currentState?.emissionPerYear),
      ratingFormatted: formatNumber(proj?.rating) ?? "-",
      translatedProjectType: i18n.te("PROJECT_TYPE." + proj?.projectType)
        ? i18n.t("PROJECT_TYPE." + proj?.projectType)
        : proj?.projectType,
    }
  }

  public formatProjectForActiveTable(proj: IProject): any {
    return {
      reductionPerYearFormatted: this.calculcateReductionPerYear(proj),
      costPerYear: formatCurrency(formatNumber(proj?.actualOutcome?.opexPerYear), "€"),
      ratingFormatted: formatNumber(proj?.rating),
      actualCostPerYearPercentage: calculatePercentage(proj?.actualOutcome?.opexPerYear, proj?.currentState?.costPerYear),
      translatedProjectType:
        i18n.te("PROJECT_TYPE." + proj?.projectType)
          ? i18n.te("PROJECT_TYPE." + proj?.projectType)
          : proj?.projectType,
      energySavedFormatted: this.calculcateEnergySaved(proj),
      energySavedPercentage: calculatePercentage(
        proj?.actualOutcome?.reductionPerYear?.value,
        proj?.potentialOutcome?.reductionPerYear?.value
      ),
      ...proj,
    }
  }

  private calculateNetSavings(project: IProject): string {
    return formatCurrency(
      formatNumber(
        subtract(
          subtract(
            project?.currentState?.costPerYear,
            project?.potentialOutcome?.opexPerYear
          ),
          project?.financing?.financingFee
        )
      ),
      '€'
    );
  }

  private calculateReductionPerYear(project: IProject): string | number {
    return formatResourceAndUnit({
      value: subtract(
        project?.currentState?.emissionPerYear?.value,
        project?.potentialOutcome?.emissionPerYear?.value
      ),
      unit: project?.currentState?.emissionPerYear?.unit,
      unitInText: null,
      resource: project?.currentState?.emissionPerYear?.resource,
    });
  }

  private calculcateReductionPerYear(proj: IProject): string | number {
    return formatResourceAndUnit({
      value: subtract(
        proj?.currentState?.emissionPerYear?.value,
        proj?.actualOutcome?.emissionPerYear?.value
      ),
      unit: proj?.currentState?.emissionPerYear?.unit,
      unitInText: null,
      resource: proj?.currentState?.emissionPerYear?.resource,
    })
  }

  private calculcateEnergySaved(proj: IProject): string | number {
    return formatResourceAndUnit({
      value: proj?.actualOutcome?.reductionPerYear?.value,
      unit: proj?.actualOutcome?.reductionPerYear?.unit,
      unitInText: proj?.actualOutcome?.reductionPerYear?.unitInText,
      resource: proj?.actualOutcome?.reductionPerYear?.resource,
    })
  }
}