import i18n from '@/i18n';
import { UserRoleEnum } from '@/shared/enum/user-roles.enum';

import type { IUserSettings } from '@/shared/interface/user-settings.interface';

import type { GetterTree } from 'vuex';
import type { RootState } from '../types';
import type { UserState } from './types';
export enum UserGetters {
  getAuth = 'user/getAuth',
  isLoggedIn = 'user/isLoggedIn',
  hasLoadedUsers = 'user/hasLoadedUsers',
  getUsers = "user/getUsers",
  getRoles = "user/userRoles",
  crudState = "user/crudState",
  getMyUser = "user/getMyUser",
  getHideAdminFeatures = "user/getHideAdminFeatures",
  getUserSettings = "user/getUserSettings",
}

export const getters: GetterTree<UserState, RootState> = {
  hasLoadedUsers(state): boolean {
    return state.usersHasLoaded;
  },
  isLoggedIn(state) {
    return state.isLoggedIn;
  },
  users(state) {
    return state.users;
  },
  getUsers(state) {
    return state.users;
  },
  userIsLoggedIn(state) {
    return state.msalAuth !== null;
  },
  msalAccessToken(state) {
    return state.msalAuth?.idToken;
  },
  getAuth(state) {
    return state.msalAuth;
  },
  getIsLoggingIn(state) {
    return state.isLoggingIn;
  },
  userRoles() {
    return [
      { value: UserRoleEnum.Admin, text: i18n.t('GENERAL.ADMIN') },
      { value: UserRoleEnum.User, text: i18n.t('GENERAL.USER') },
    ]
  },
  crudState(state) {
    return state.crudState;
  },
  getMyUser(state) {
    return state.myUser;
  },
  getHideAdminFeatures(state) {
    return state.userSettings?.hideAdminFeatures ?? false;
  },
  getUserSettings(state): IUserSettings {
    return state.userSettings;
  }
}