const editIcon = 'pencil';
const viewIcon = 'eye';
const deleteIcon = 'trash';
const addIcon = 'plus';
const searchIcon = 'search';
const userIcon = 'person';
const signOutIcon = 'box-arrow-right';
const chevronUpIcon = 'chevron-up';
const chevronDownIcon = 'chevron-down';
const minusIcon = 'dash';

export {
  editIcon,
  viewIcon,
  deleteIcon,
  addIcon,
  searchIcon,
  userIcon,
  signOutIcon,
  chevronUpIcon,
  chevronDownIcon,
  minusIcon
}