import type { Module } from 'vuex';
import type { RootState } from '@/store/types';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import type { TagState } from './types';

export const state: TagState = {
  tags: [],
  tagsHasLoaded: false,
  crudState: null,
};

const namespaced = true;

export const tagState: Module<TagState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
