import type _Vue from 'vue';

import * as _btns from './buttons';
import * as _table from './tables';
import * as _inputs from './inputs';
import * as _avatar from './avatar';
import * as _modals from './modal';
import * as _lists from './list';
import * as _spinners from './spinners';
import * as _alerts from './alerts';
import * as _card from './card';
import * as _grid from './grid';
import * as _tag from './tags'
import * as _dividers from './dividers';
import * as _wizard from './wizard';
import * as _stepper from './stepper';
export default {
  install(Vue: typeof _Vue): void {
    Vue.component('PrimaryButton', _btns.PrimaryButton);
    Vue.component('SecondaryButton', _btns.SecondaryButton);
    Vue.component('LoginButton', _btns.LoginButton);
    Vue.component('ButtonActionGroup', _btns.ButtonActionGroup);
    Vue.component('Button', _btns.ButtonComponent);
    Vue.component('PillButton', _btns.PillButton);
    Vue.component('Table', _table.Table);
    Vue.component('TextField', _inputs.TextFieldComponent);
    Vue.component('FormSelect', _inputs.FormSelectComponent);
    Vue.component('Menu', _inputs.MenuComponent);
    Vue.component('DatePicker', _inputs.DatePickerComponent);
    Vue.component('NumberInputField', _inputs.NumberInputFieldComponent);
    Vue.component('TextArea', _inputs.TextAreaComponent);
    Vue.component('InitialsAvatar', _avatar.InitialsAvatarComponent);
    Vue.component('InfoBlob', _avatar.InfoBlobComponent);
    Vue.component('Modal', _modals.ModalComponent);
    Vue.component('List', _lists.ListComponent);
    Vue.component('Spinner', _spinners.SpinnerComponent);
    Vue.component('Alert', _alerts.AlertComponent);
    Vue.component('Toast', _alerts.ToastComponent);
    Vue.component('GlobalToast', _alerts.GlobalToast);
    Vue.component('Card', _card.CardComponent);
    Vue.component('Grid', _grid.GridComponent);
    Vue.component('Tag', _tag.TagComponent);
    Vue.component('Divider', _dividers.DividerComponent);
    Vue.component('Wizard', _wizard.WizardComponent);
    Vue.component('WizardStep', _wizard.WizardStepComponent);
    Vue.component('StepperComponent', _stepper.Stepper);
  },
};
