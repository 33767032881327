import type { IAlert } from '@/shared/interface/alert.interface';
import { Toast } from '@/shared/models/toast.model';
import type { ActionTree } from 'vuex';
import type { RootState } from '../types';
import type { AlertsState } from './types';
export enum AlertActions {
  QueueAlert = 'alerts/QueueAlert',
  DismissAlert = 'alerts/DismissAlert',
  QueueTost = "alerts/QueueToast",
  DismissToast = "alerts/DismissToast"
}

export const actions: ActionTree<AlertsState, RootState> = {
  DismissAlert({ commit }, payload: IAlert): void {
    commit('removeAlert', payload);
  },
  QueueAlert({ commit }, payload: IAlert): void {
    commit('addAlert', payload);
  },
  QueueToast({ commit }, payload: IAlert): void {
    commit('addToast', new Toast(payload.type, payload.text));
  },
  DismissToast({ commit }, payload: IAlert): void {
    commit('removeToast', payload);
  }
};
