import type { IAlert } from '@/shared/interface/alert.interface';
import type { GetterTree } from 'vuex';
import type { RootState } from '../types';
import type { AlertsState } from './types';

export enum AlertsGetters {
  getAlerts = 'alerts/getAlerts',
  getToasts = 'alerts/getToasts'
}
export const getters: GetterTree<AlertsState, RootState> = {
  getAlerts(state): IAlert[] {
    return state.alerts;
  },
  getToasts(state): IAlert[] {
    return state.toasts;
  }
};
