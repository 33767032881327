import type { ICrudState } from '@/shared/interface/crud-state.interface';
import type { Tag } from '@/shared/interface/tag.interface';
import type { MutationTree } from 'vuex';
import type { TagState } from './types';


export const mutations: MutationTree<TagState> = {
  setTags(state, tags: Tag[]) {
    state.tags = tags ?? [];
  },
  hasLoadedTags(state, payload: boolean) {
    state.tagsHasLoaded = payload
  },
  setCrudState(state, crud: ICrudState) {
    state.crudState = crud;
  },
  clearCrudState(state, payload?: number) {
    if (payload && payload > 0) {
      setTimeout(() => {
        state.crudState = null;
      }, payload);
    } else {
      state.crudState = null;
    }
  },
  clearTagFromState(state, tag: Tag) {
    state.tags = state.tags.filter((stateState) => stateState.id !== tag.id);
  },
  addTagToState(state, tag: Tag) {
    state.tags = [...state.tags ?? [], tag];
  },
  updateTagsInState(state, tags: Tag[]) {
    const newState = state.tags.map((tag) => {
      const updatedTag = tags.find((updatedTag) => updatedTag.id === tag.id);
      return updatedTag ? updatedTag : tag;
    });
    state.tags = [...newState]
  }
}