import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

import type { Module } from 'vuex';
import type { RootState } from '@/store/types';
import type { GroupState } from './types';

export const state: GroupState = {
  groups: [],
  groupsHasLoaded: false,
  crudState: null,
};

const namespaced = true;

export const groupState: Module<GroupState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
