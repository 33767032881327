const guidIsEmpty = (guid: string): boolean => {
  return guid === emptyGuid();
}

const emptyGuid = (): string => {
  return '00000000-0000-0000-0000-000000000000';
}

export {
  guidIsEmpty,
  emptyGuid
}