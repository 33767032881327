import { TagService } from '@/services/tag.service';
import { AlertType } from '@/shared/interface/alert.interface';
import type { Tag } from '@/shared/interface/tag.interface';
import type { ActionTree } from 'vuex';
import { AlertActions } from '../alerts/actions';
import type { RootState } from '../types';
import type { TagState } from './types';

export enum TagActions {
  getTags = 'tags/getTags',
  addTag = 'tags/addTag',
  updateTag = "tags/updateTag",
  deleteTag = 'tags/deleteTag',
  clearTagCrudState = 'tags/clearTagCrudState',
}

export const actions: ActionTree<TagState, RootState> = {
  async getTags({ dispatch, commit }): Promise<void> {
    try {
      const tags: Tag[] = await new TagService().getTags();
      commit("setTags", tags);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true });
      commit("setTags", []);
    }
    commit('hasLoadedTags', true);
  },

  async deleteTag({ dispatch, commit }, tag: Tag): Promise<void> {
    commit('setCrudState', { loading: true, success: null }); try {
      await new TagService().deleteTag(tag.id);
      dispatch(AlertActions.QueueTost, { text: "TAG.CRUD.DELETE_SUCCESS", type: AlertType.Success }, { root: true })
      commit('setCrudState', { loading: false, success: true });
      commit('clearTagFromState', tag);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, message: '', success: false });
    }
  },

  async addTag({ dispatch, commit }, tag: Tag): Promise<void> {
    commit('setCrudState', { loading: true, success: null });
    try {
      const newTag = await new TagService().createTag(tag);
      dispatch(AlertActions.QueueTost, { text: "TAG.CRUD.CREATE_SUCCESS", type: AlertType.Success }, { root: true })
      commit('setCrudState', { loading: false, success: true });
      commit('addTagToState', newTag);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, success: false });
    }
  },

  async updateTag({ dispatch, commit }, tag: Tag): Promise<void> {
    commit('setCrudState', { loading: true, success: null });
    try {
      const updatedTag = await new TagService().updateTag(tag);
      dispatch(AlertActions.QueueTost, { text: "TAG.CRUD.UPDATE_SUCCESS", type: AlertType.Success }, { root: true })
      commit('setCrudState', { loading: false, success: true });
      commit('updateTagsInState', [updatedTag]);
    } catch (error: any) {
      dispatch(AlertActions.QueueAlert, {
        text: error.message,
        type: AlertType.Danger
      }, { root: true })
      commit('setCrudState', { loading: false, success: false });
    }
  },
  clearTagCrudState({ commit }): void {
    commit('clearCrudState');
  }
}