import type { MutationTree } from 'vuex';
import type { AlertsState } from './types';

export const mutations: MutationTree<AlertsState> = {
  addAlert(state, payload) {
    state.alerts.push(payload);
  },
  removeAlert(state, payload) {
    state.alerts = state.alerts.filter((a) => a.id !== payload.id);
  },
  addToast(state, payload) {
    state.toasts.push(payload);
  },
  removeToast(state, payload) {
    state.toasts = state.toasts.filter((a) => a.id !== payload.id);
  }
};
